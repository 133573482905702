import secureLocalStorage from "react-secure-storage";

export function bearerToken() {
  const savedToken = secureLocalStorage.getItem("token");
  const token = savedToken ? JSON.parse(savedToken) : null;
  return token?.access
    ? {
        Authorization: `Bearer ${token?.access}`,
      }
    : {};
}

export default bearerToken;
