import moment from "moment";

export function utcDate(date) {
  return moment.utc(date).format();
}

export function utcDateTime(date, time) {
  const tz = new Date().getTimezoneOffset() / 60;
  const utc_date = utcDate(`${date}${date.includes("T") ? "" : " "}${time}`);
  const offset_date = moment(utc_date).utcOffset(tz);
  const offsetDate = moment(offset_date).format("YYYY-MM-DD");
  const offsetTime = moment(offset_date).format("HH:mm:ss");
  return `${offsetDate} ${offsetTime}`;
}

export function utcDateToDisplay(date) {
  const tz = new Date().getTimezoneOffset() / 60;
  return moment(date).utcOffset(-tz);
}

export default utcDate;
