import { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Heading6, Icon } from "..";

const SearchCard = ({ type = "default", data, selected = false, onClick = () => {} }) => {
  const themeContext = useContext(ThemeContext);
  return (
    <StyledCardContent
      id={`${selected ? "selected" : "unselected"}-${data?.id}-${data.title.replace(
        /[^0-9a-zA-Z]/g,
        ""
      )}-search-filter-option`}
      type={type}
      selected={selected}
      onClick={onClick}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          onClick();
        }
      }}
      tabIndex={0}
    >
      {selected && type !== "filtered" && (
        <StyledCheckedIcon
          name={"check-circle"}
          height={"2rem"}
          width={"2rem"}
          fill={themeContext.palette.neutralWhite}
        />
      )}
      <StyledSearchTitle selected={selected} type={type}>
        {data.title.toUpperCase()}
      </StyledSearchTitle>
      {selected && type === "filtered" && (
        <StyledRightIcon
          name={"times-circle"}
          height={"1.4rem"}
          width={"1.4rem"}
          fill={themeContext.palette.primaryBlue}
        />
      )}
    </StyledCardContent>
  );
};

const StyledCardContent = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme, selected, type }) =>
    selected && type !== "filtered" ? theme.palette.primaryBlue : theme.palette.neutralWhite};
  margin: 0.25rem;
  box-shadow: ${({ selected }) => (selected ? "none" : "0px 8px 12px rgba(0, 0, 0, 0.12);")};
  border-radius: 1rem;
  padding: ${({ type }) => (type !== "filtered" ? `1rem` : `0.5rem`)};
  padding-left: ${({ type }) => (type !== "filtered" ? `1.5rem` : `1rem`)};
  padding-right: ${({ type }) => (type !== "filtered" ? `1.5rem` : `1rem`)};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme, selected, type }) =>
      selected && type !== "filtered" ? theme.palette.hoverPrimary : theme.palette.neutralGrey};
  }

  &:active {
    background-color: ${({ theme, selected, type }) =>
      selected && type !== "filtered" ? theme.palette.pressedDark : theme.palette.pressedLight};
  }
  height: ${({ type }) => (type === "filtered" ? "2.5rem" : "auto")};
  align-items: center;
`;

const StyledSearchTitle = styled(Heading6)`
  color: ${({ theme, selected, type }) =>
    selected && type !== "filtered" ? theme.palette.neutralWhite : theme.palette.primaryBlue};
  font-weight: 700;
`;

const StyledCheckedIcon = styled(Icon)`
  margin-right: 0.5rem;
`;

const StyledRightIcon = styled(Icon)`
  margin-left: 0.3rem;
`;

export default SearchCard;
