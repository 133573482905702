import * as React from "react";

import AuthLayout from "../App/AuthLayout";

import UserContextProvider from "../_context/UserContext";
import { ConsentsProvider } from "../_context/ConsentsContext";
import { OrgProFeaturesContextProvider } from "../_context/OrgProFeaturesContext";
import WearableContextProvider from "../_context/WearableContext";
import AuthPssLayout from "../App/AuthPssLayout";

const AuthorizedRouteContexts = ({ children }) => {
  return (
    <UserContextProvider>
      <ConsentsProvider>
        <OrgProFeaturesContextProvider>
          <WearableContextProvider>{children}</WearableContextProvider>
        </OrgProFeaturesContextProvider>
      </ConsentsProvider>
    </UserContextProvider>
  );
};

export const AuthorizedRoute = ({ children, noShell, type = "user" }) => {
  return () =>
    noShell ? (
      <AuthorizedRouteContexts>{children}</AuthorizedRouteContexts>
    ) : (
      <AuthorizedRouteContexts>
        <AuthWrappedRoute type={type}>{children}</AuthWrappedRoute>
      </AuthorizedRouteContexts>
    );
};

export const AuthWrappedRoute = ({ children, type = "user" }) => {
  return type === "pss" ? (
    <AuthPssLayout>{children}</AuthPssLayout>
  ) : (
    <AuthLayout>{children}</AuthLayout>
  );
};

export const renderAuthorizedRoute = ({ children, noShell, type }) => () => (
  <AuthorizedRoute noShell={noShell} type={type}>
    {children}
  </AuthorizedRoute>
);

export default AuthorizedRoute;
