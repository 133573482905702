import React from "react";
import styled from "styled-components";

const CustomDropdownButton = React.forwardRef(({ children, onClick }, ref) => (
  <Wrapper
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </Wrapper>
));

const Wrapper = styled.div`
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
`;

export default CustomDropdownButton;
