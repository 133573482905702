import React, { memo } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Menu, MenuItem, LinkText } from "../../App/_components/Menu";

const CustomDropdownButton = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    role="link"
    aria-label={"class homework link remind me later"}
    tabIndex="0"
    style={{ color: "white" }}
  >
    {children}
  </div>
));

const DropdownBasic = ({ id, toggle, menuAlign = "right", options }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomDropdownButton} id={id}>
        {toggle}
      </Dropdown.Toggle>

      <Menu align={menuAlign}>
        {options?.map((option, i) => (
          <MenuItem
            key={option?.key || i}
            role={option?.role}
            as={option?.as === "link" ? Link : option?.as}
            to={option?.to}
            href={option?.href}
            active={option?.active}
            target={option?.target}
            rel={option?.rel}
            onClick={option?.onClick}
          >
            <LinkText $active={option?.active}>{option?.title}</LinkText>
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
};

export default memo(DropdownBasic);
