import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Col, Row } from "./Layout";
import { Paragraph1, Paragraph2 } from "./Typography";
import Button from "./Buttons/Button";
import Modal from "./Modal";
import PageLoader from "./PageLoader";

import { authAxios } from "../_utils";

import { ROUTES } from "../_routes";

const SavePlaylistModal = ({ show, setShow, course }) => {
  const navigate = useNavigate();
  const [playlists, setPlaylists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAlreadyAdded, setShowAlreadyAdded] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if (show) {
      setLoading(true);
      setShowAlreadyAdded(false);
      authAxios({
        method: "GET",
        url: `playlists/ids_titles`,
      })
        .then((res) => {
          setPlaylists(res.data.items);
        })
        .finally(() => setLoading(false));
    }
  }, [show]);

  const handleAddToPlaylist = (id) => {
    if (submitLoading) return;
    setSubmitLoading(true);
    authAxios({
      method: "POST",
      url: `playlistcourses/add/${id}/${course.id}`,
    })
      .then(() => {
        setShow(false);
        setSubmitLoading(false);
      })
      .catch(() => {
        setShowAlreadyAdded(true);
      });
  };

  const handleRedirectToPlaylist = (e) => {
    e?.stopPropagation();
    const data = JSON.stringify({
      url: window.location.pathname,
      course_id: course.id,
    });
    localStorage.setItem("playlist-redirect", data);
    navigate(`${ROUTES.PLAYLIST}${ROUTES.NEW}`);
  };

  const handleClose = (e) => {
    e?.stopPropagation();
    setShow(false);
  };

  const handlePropagation = (e) => {
    e?.stopPropagation();
  };

  if (!show) return null;
  return (
    <div
      onClick={handlePropagation}
      onKeyDown={handlePropagation}
      onFocus={handlePropagation}
      onMouseOver={handlePropagation}
    >
      <Modal show={show} onHide={handleClose} title="Save to playlist">
        {loading ? (
          <PageLoader />
        ) : showAlreadyAdded ? (
          <Col xs={12} $align="center">
            <Paragraph2 cursor $mb={2}>
              {course.title || "This course"} is already in this playlist.
            </Paragraph2>
            <Button onClick={handleClose}>Dismiss</Button>
          </Col>
        ) : (
          <Row>
            <Col xs={12} $align="center">
              <Paragraph1
                $amt={2}
                $ellipsis
                weight={700}
                cursor
                $my={2}
                onClick={handleRedirectToPlaylist}
                id="create-playlist"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleRedirectToPlaylist();
                  }
                }}
              >
                Create a new playlist
              </Paragraph1>
            </Col>
            {playlists?.length > 0 ? (
              playlists.map((item) => (
                <Col xs={12}>
                  <Paragraph1
                    $amt={2}
                    $ellipsis
                    weight={700}
                    id={`add-to-playlist-${item?.playlist?.id}`}
                    cursor
                    $my={2}
                    onClick={() => handleAddToPlaylist(item?.playlist?.id)}
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleAddToPlaylist(item?.playlist?.id);
                      }
                    }}
                  >
                    {item?.playlist?.title}
                  </Paragraph1>
                </Col>
              ))
            ) : (
              <Col xs={12} $align="center">
                <Paragraph2 cursor $mb={2}>
                  You have no playlists.
                </Paragraph2>
                <Button
                  tabIndex={0}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleRedirectToPlaylist();
                    }
                  }}
                  onClick={handleRedirectToPlaylist}
                  id="create-playlist"
                >
                  Create Playlist
                </Button>
              </Col>
            )}
          </Row>
        )}
      </Modal>
    </div>
  );
};

export default SavePlaylistModal;
