import { css } from "styled-components";

/* DEV NOTE: alphabatize these components --- or group them with a note and alphabatize */
export const baseCard = css`
  display: flex;
  background-color: ${({ theme }) => theme.palette.neutralWhite};
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  padding: 1.5rem;
`;

export const ellipsis = css`
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const ellipsisOptional = css`
  text-overflow: ${({ $ellipsis }) => $ellipsis && "ellipsis"};
  white-space: ${({ $ellipsis }) => $ellipsis && "normal"};
  display: ${({ $ellipsis }) => $ellipsis && "-webkit-box"};
  -webkit-box-orient: ${({ $ellipsis }) => $ellipsis && "vertical"};
  overflow: ${({ $ellipsis }) => $ellipsis && "hidden"};
`;

export const lineClamp = css`
  display: -webkit-box;
  -webkit-line-clamp: ${({ $amt }) => ($amt ? $amt : 1)};
  height: ${({ $clampHeight }) => $clampHeight};
`;

export const lineClampOptional = css`
  display: ${({ $lineClamp }) => $lineClamp && "-webkit-box"};
  -webkit-line-clamp: ${({ $amt }) => ($amt ? $amt : 1)};
  height: ${({ $clampHeight }) => $clampHeight};
`;

export const noMargin = css`
  margin: ${({ margin, m, $m }) => margin || m || $m};
  margin: ${({ $m }) => $m && `${$m * 0.25}rem !important`};
  margin-top: ${({ my }) => my && `${my * 0.25}rem !important`};
  margin-top: ${({ $my }) => $my && `${$my * 0.25}rem !important`};
  margin-top: ${({ mt }) => mt && `${mt * 0.25}rem !important`};
  margin-top: ${({ $mt }) => $mt && `${$mt * 0.25}rem !important`};
  margin-bottom: ${({ my }) => my && `${my * 0.25}rem !important`};
  margin-bottom: ${({ $my }) => $my && `${$my * 0.25}rem !important`};
  margin-bottom: ${({ mb }) => mb && `${mb * 0.25}rem !important`};
  margin-bottom: ${({ $mb }) => $mb && `${$mb * 0.25}rem !important`};
  margin-left: ${({ mx }) => mx && `${mx * 0.25}rem !important`};
  margin-left: ${({ $mx }) => $mx && `${$mx * 0.25}rem !important`};
  margin-left: ${({ ml }) => ml && `${ml * 0.25}rem !important`};
  margin-left: ${({ $ml }) => $ml && `${$ml * 0.25}rem !important`};
  margin-left: ${({ $mxauto }) => $mxauto && "auto !important"};
  margin-right: ${({ mx }) => mx && `${mx * 0.25}rem !important`};
  margin-right: ${({ $mx }) => $mx && `${$mx * 0.25}rem !important`};
  margin-right: ${({ mr }) => mr && `${mr * 0.25}rem !important`};
  margin-right: ${({ $mr }) => $mr && `${$mr * 0.25}rem !important`};
  margin-right: ${({ $mxauto }) => $mxauto && "auto !important"};
`;

export const noPadding = css`
  padding: ${({ padding, p, $p }) => padding || p || $p};
  padding-top: ${({ $py }) => $py && `${$py * 0.25}rem !important`};
  padding-top: ${({ py }) => py && `${py * 0.25}rem !important`};
  padding-top: ${({ pt }) => pt && `${pt * 0.25}rem !important`};
  padding-top: ${({ $pt }) => $pt && `${$pt * 0.25}rem !important`};
  padding-bottom: ${({ py }) => py && `${py * 0.25}rem !important`};
  padding-bottom: ${({ $py }) => $py && `${$py * 0.25}rem !important`};
  padding-bottom: ${({ pb }) => pb && `${pb * 0.25}rem !important`};
  padding-bottom: ${({ $pb }) => $pb && `${$pb * 0.25}rem !important`};
  padding-left: ${({ px }) => px && `${px * 0.25}rem !important`};
  padding-left: ${({ $px }) => $px && `${$px * 0.25}rem !important`};
  padding-left: ${({ $pl }) => $pl && `${$pl * 0.25}rem !important`};
  padding-left: ${({ pl }) => pl && `${pl * 0.25}rem !important`};

  padding-right: ${({ px }) => px && `${px * 0.25}rem !important`};
  padding-right: ${({ $px }) => $px && `${$px * 0.25}rem !important`};
  padding-right: ${({ pr }) => pr && `${pr * 0.25}rem !important`};
  padding-right: ${({ $pr }) => $pr && `${$pr * 0.25}rem !important`};
`;

export const GeneralStyle = {
  baseCard: baseCard,
  ellipsis: ellipsis,
  lineClamp: lineClamp,
  noPadding: noPadding,
  noMargin: noMargin,
};

export default GeneralStyle;
