import { useState, useCallback, memo, useContext } from "react";
import { connect } from "react-redux";
import Nav from "react-bootstrap/Nav";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import NavLink from "../../App/_components/NavLink";
import { Link, Row, Icon } from "../../_components";
import { IconButton } from "../../_components/Icon";
import { UserNavSideBarSection } from "./_components";

import { UserContext } from "../../_context/UserContext";
import { doLogoutAsync } from "../../_redux/actions/auth/doLogoutAsync";
import { useGetMenuItems } from "../../App/_components/_utils";
import { useMedia } from "../../_hooks";

import { ROUTES } from "../../_routes";

const Sidenav = ({ doLogout }) => {
  const navigate = useNavigate();
  const { userData, loading } = useContext(UserContext);
  const { isAdmin, isManager, isOwner } = userData;
  const isMobile = useMedia("(max-width: 767px)");
  const [drawer, setDrawer] = useState();
  const pssItems = useGetMenuItems("pss");

  const setDrawerCallback = useCallback(() => setDrawer(), []);

  const logout = () => doLogout(navigate);

  return (
    <Wrapper $open={Boolean(drawer)}>
      <Row $py={3}>
        <Link id={`partner-portal-link`} to={ROUTES.PSS} $px={3} aria-label="partner portal link">
          <Icon name="logo-full" width="11em" />
        </Link>
      </Row>

      <hr className="m-0" />

      {loading ? (
        [...Array(9)].map(function (_, i) {
          return <Skeleton key={i} height="2.8em" width="100%" className="mb-1" />;
        })
      ) : (
        <>
          <UserNavSideBarSection show={isAdmin || isManager || isOwner} userData={userData} />
          <Nav activeKey="/" className="flex-column">
            {pssItems?.map((item, i) => {
              if (item.hide) return null;
              return (
                <NavLink
                  key={i}
                  name={item.icon}
                  label={item.label}
                  id={item.id}
                  linkTo={item.link}
                  onClick={setDrawerCallback}
                  fill={item.fill}
                  type={item.line ? "line" : "link"}
                  pss
                />
              );
            })}
            <NavLink id="logout-pss" label="Logout" type="link" onClick={logout} pss />
          </Nav>
        </>
      )}

      {isMobile ? (
        <MobileIconButton onClick={() => setDrawer(!drawer)} name={drawer ? "times" : "bars"} />
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  position: fixed;
  top: 0;
  left: ${({ $open }) => ($open ? 0 : "-14rem")};
  width: 17rem;
  height: 100%;
  padding-right: ${({ $open }) => ($open ? 0 : "3rem")};
  background: ${({ theme }) => theme.palette.neutralWhite};
  transition: left 0.3s ease, padding 0.3s ease;
  z-index: ${({ $open }) => ($open ? "1010" : "1009")};

  @media (min-width: 768px) {
    position: relative;
    top: auto;
    left: auto;
    width: 14rem;
    padding-right: 0;
  }
`;

const MobileIconButton = styled(IconButton)`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
`;

export default connect(null, {
  doLogout: doLogoutAsync,
})(memo(Sidenav));
