import { TOKEN_REQUEST, TOKEN_SUCCESS, TOKEN_FAILURE } from "./token";

const initialState = {
  isFetching: false,
  errors: null,
};

export const refresh = (state = initialState, action) => {
  const { errors } = action;

  switch (action.type) {
    case TOKEN_REQUEST:
      return {
        isFetching: true,
        errors: null,
      };
    case TOKEN_SUCCESS:
      return {
        isFetching: false,
        errors: null,
      };
    case TOKEN_FAILURE:
      return {
        isFetching: false,
        errors,
      };
    default:
      return state;
  }
};

export default refresh;
