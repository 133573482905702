import React, { useRef, useState } from "react";
import Overlay from "react-bootstrap/Overlay";
import { Row } from "../Layout";

import { IconButton } from "../Icon";
import { CardBasicForm } from "../_cards/_style";

const OptionMenu = ({
  placement = "top",
  children,
  dotColor,
  dropdown,
  customShow,
  setCustomShow,
  showButton = false,
  showText = false,
  label,
  useRefTargetContainer,
  ariaLabel = "option menu toggle",
}) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const toggleShow = () => {
    if (setCustomShow) setCustomShow(!customShow);
    else setShow(!show);
  };

  return (
    <>
      <Row $justify={"flex-end"}>
        <IconButton
          label={label}
          fill={dotColor}
          ref={target}
          name={dropdown ? (showButton ? dropdown : null) : "ellipsis-h"}
          size={"24px"}
          onClick={() => toggleShow()}
          text={showText && dropdown ? dropdown : null}
          aria-label={ariaLabel}
          tabIndex={0}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              toggleShow && toggleShow();
            }
          }}
        />
      </Row>
      <Overlay
        container={useRefTargetContainer ? target.current : null}
        target={target.current}
        show={customShow ? customShow : show}
        placement={placement}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <CardBasicForm
            {...props}
            $p="8"
            style={{
              padding: "12px",
              boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.12)",
              ...props.style,
              position: "fixed",
              scrollBehavior: "inherit",
              zIndex: 99999,
            }}
            onClick={() => {
              if (!dropdown) toggleShow();
            }}
          >
            {children}
          </CardBasicForm>
        )}
      </Overlay>
    </>
  );
};

export default OptionMenu;
