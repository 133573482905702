import React from "react";
import styled from "styled-components";

export const Hyperlink = ({ children, target, title, aria, ...rest }) => {
  const label = typeof children === "string" ? children : "";
  let unique_id = Math.random().toString(36).slice(2, 7);
  return (
    <ATag
      target={target}
      rel={target ? "noopener noreferrer" : null}
      title={title || label}
      id={title ? `${title}_${unique_id}` : `${label}_${unique_id}`}
      aria-label={aria || null}
      {...rest}
    >
      {children}
    </ATag>
  );
};

const ATag = styled.a`
  cursor: pointer;
  text-decoration: ${(props) => (props.underline ? "underline" : "none")};
  color: ${(props) => (props.color ? props.color : props.theme.palette.primaryBlue)};
  display: inline-block;
  &:hover {
    cursor: pointer;
    text-decoration: ${(props) => (props.underline ? "underline" : "none")};
    color: ${(props) => props.theme.palette.hoverPrimary};
  }
`;

export default Hyperlink;
