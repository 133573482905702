import { useEffect, useRef } from "react";

const useIntervalCall = (elapsed, interval, onInterval, reset) => {
  const guardRef = useRef(-1);

  useEffect(() => {
    if (reset) {
      guardRef.current = -1;
    }
  }, [reset]);

  useEffect(() => {
    if (elapsed % interval === 0 && guardRef.current !== elapsed && !reset) {
      guardRef.current = elapsed;
      onInterval && onInterval();
    }
  }, [elapsed, interval, onInterval, reset]);

  return () => (guardRef.current = -1);
};

export default useIntervalCall;
