export const convertHexToRGBA = (hexCode, opacity) => {
  var hex = hexCode?.replace("#", "");

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  var r = parseInt(hex.substring(0, 2), 16),
    g = parseInt(hex.substring(2, 4), 16),
    b = parseInt(hex.substring(4, 6), 16);

  return "rgba(" + r + "," + g + "," + b + "," + opacity / 100 + ")";
};

export const baColor = (color, theme) => {
  switch (color) {
    case "primary":
    case "blue":
      return theme.palette.primaryBlue;
    case "white":
      return theme.palette.neutralWhite;
    case "red":
    case "alert":
      return theme.palette.red001;
    case "green":
    case "level1":
      return theme.palette.level1Green;
    case "level2":
      return theme.palette.level2Purple;
    case "level3":
      return theme.palette.level3Pink;
    case "orangeStar":
      return theme.palette.orangeStar;
    case "grey1":
      return theme.palette.neutralGrey;
    case "grey2":
      return theme.palette.neutralDarkerGrey;
    case "grey3":
      return theme.palette.neutralDarkestGrey;
    case "pink":
      return theme.palette.basePink;
    case "teal":
      return theme.palette.baseTeal;
    case "yellow":
      return theme.palette.baseYellow;
    case "orange":
      return theme.palette.baseOrange;
    case "purple":
      return theme.palette.basePurple;
    case "brightBlue":
      return theme.palette.brightBlue;
    case "hoverBrightBlue":
      return theme.palette.hoverBrightBlue;
    case "brightOrange":
      return theme.palette.brightOrange;
    case "hoverBrightOrange":
      return theme.palette.hoverBrightOrange;
    case "brightYellow":
      return theme.palette.brightYellow;
    case "hoverBrightYellow":
      return theme.palette.hoverBrightYellow;
    case "brightGreen":
      return theme.palette.brightGreen;
    case "hoverBrightGreen":
      return theme.palette.hoverBrightGreen;
    case "blue001":
      return theme.palette.blue001;
    case "liveRed":
      return theme.palette.liveRed;
    case "tagBlue":
      return theme.palette.tagBlue;
    default:
      return color;
  }
};
