export const EVENTS_ALERT = "EVENTS_ALERT";
export const EVENTS_DISMISS = "EVENTS_DISMISS";

const initialState = {
  show: false,
  header: "",
  subHeader: "",
  body: "",
  url: "",
};

export const event = (state = initialState, action) => {
  switch (action.type) {
    case EVENTS_ALERT:
      return {
        ...state,
        ...action,
        show: true,
      };
    case EVENTS_DISMISS:
      return initialState;
    default:
      return state;
  }
};

export default event;
