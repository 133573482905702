import { useState, useEffect, useRef } from "react";

const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false);
  const mountRef = useRef(false);

  useEffect(() => {
    if (mountRef.current === false) {
      mountRef.current = true;
      setIsMounted(true);
    }
    return () => (mountRef.current = false);
  }, [isMounted]);

  return mountRef.current;
};

export default useIsMounted;
