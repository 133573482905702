import React, { memo } from "react";
import styled from "styled-components";
import { ListGroup as List } from "react-bootstrap";
import { useMedia } from "../../_hooks";
import { Separator } from "..";

const ListGroup = ({ items, loading, heading, children }) => {
  const isMobile = useMedia("(max-width: 767px)");

  return (
    <>
      {heading && !isMobile && (
        <>
          {heading}
          <Separator $width="100%" $my={2} />
        </>
      )}
      <ListGroupWrapper variant="flush">{children}</ListGroupWrapper>
    </>
  );
};

const ListGroupWrapper = styled(List)`
  width: 100%;
`;

export const ListItem = ({ children, ...rest }) => {
  return (
    <ListItemWrapper action {...rest}>
      {children}
    </ListItemWrapper>
  );
};

const ListItemWrapper = styled(List.Item)`
  padding-left: 0;
  padding-right: 0;
`;

export default memo(ListGroup);
