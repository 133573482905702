import React, { memo } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import styled from "styled-components";
import { Paragraph3 } from "../Typography";
import { noMargin } from "../../_theme/general";

const FormGroup = ({
  children,
  label,
  name,
  append,
  prepend,
  error,
  errors,
  errorLinkAppend,
  blurred,
  focused,
  helperText,
  helperColor = "grey",
  required,
  ...rest
}) => {
  return (
    <FormGroupWrapper controlId={name} required={required} {...rest}>
      {label && (
        <FormLabel>
          {label}
          {required && (
            <Paragraph3 as="span" color="red" $mb={0}>
              {" "}
              *
            </Paragraph3>
          )}
        </FormLabel>
      )}
      {prepend || append ? (
        <InputGroup>
          {prepend && (
            <InputAdditonal $pre $blur={blurred} $focus={focused}>
              {prepend}
            </InputAdditonal>
          )}
          {children}
          {append && (
            <InputAdditonal $append $blur={blurred} $focus={focused}>
              {append}
            </InputAdditonal>
          )}
        </InputGroup>
      ) : (
        children
      )}
      {helperText && (
        <Paragraph3 color={helperColor} pt={1}>
          {helperText}
        </Paragraph3>
      )}
      {(error || errorLinkAppend) && (
        <Paragraph3 color="red" $mt={1}>
          {error}
        </Paragraph3>
      )}
      {errors && (
        <Paragraph3 color="red" $mt={1}>
          {errors}
        </Paragraph3>
      )}
    </FormGroupWrapper>
  );
};

export const FormGroupWrapper = styled(Form.Group)`
  width: ${({ $width }) => $width};
  display: flex;
  flex-direction: column;
  text-align: left;

  ${noMargin};
`;

const InputAdditonal = styled(InputGroup.Text)`
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.palette.primaryBlue};
`;

export const FormLabel = styled(Form.Label)`
  color: ${({ theme }) => theme.palette.primaryBlue};
  margin-bottom: 0.25rem;
  font-weight: 700;
  text-align: ${({ textAlign, $textAlign }) => textAlign || $textAlign};
`;

export default memo(FormGroup);
