import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

import { finderShowOnMobile } from "../_redux/actions/search/finder";

function ScrollToTop({ showFinderOnMobile }) {
  // TODO: Figure out to make scrolling to top optional per route
  const { pathname } = useLocation();
  const [prevPathname, setPrevPathname] = useState();
  // const dirs = 0; // pathname.split("/").length;

  useEffect(() => {
    if (pathname !== prevPathname) {
      showFinderOnMobile(false);
      setPrevPathname(pathname);
      window.scrollTo({
        // top: dirs >= 4 ? window.pageYOffset : 0,
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [pathname, prevPathname, setPrevPathname, showFinderOnMobile]);

  return null;
}

export default connect(null, {
  showFinderOnMobile: finderShowOnMobile,
})(ScrollToTop);
