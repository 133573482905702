import { useEffect, useState } from "react";
import moment from "moment";
import { utcDateToDisplay, utcDateTime } from "../../_utils";

const missedClassBuffer = 30; //minutes

export const useProgramCourseStatus = (type, data, enrolled) => {
  const [time, setScheduled] = useState();
  const [missed, setMissed] = useState();
  const [completed, setCompleted] = useState();

  useEffect(() => {
    const scheduled =
      data?.type === "live" ? data?.schedule?.date_schedule || "" : data?.date_scheduled || "";

    setScheduled(
      utcDateTime(moment(scheduled).format("YYYY-MM-DD"), moment(scheduled).format("HH:mm:ss"))
    );
  }, [data]);

  useEffect(() => {
    if (type && type === "program") {
      const isComplete = (data?.status === "inactive" || data?.status === "complete") && enrolled;
      setCompleted(isComplete);
    }
  }, [data?.status, type, enrolled]);

  useEffect(() => {
    if (type && type === "program") {
      const isMissed =
        !completed &&
        data?.date_scheduled &&
        enrolled &&
        moment(time).isBefore(moment().subtract(missedClassBuffer, "minutes"));
      setMissed(isMissed);
    }
  }, [completed, data?.date_scheduled, enrolled, time, type]);

  return { missed, completed, time };
};

export const useLiveClassStatus = (time) => {
  const [canJoin, setCanJoin] = useState(false);

  useEffect(() => {
    if (time) {
      const minutesOffSet = 5;
      const now = new Date();
      const minutesOffset = new Date(moment(now).add(5, "minutes").format());
      const showJoinCourse =
        moment(minutesOffset).diff(moment(utcDateToDisplay(time)).format(), "minutes") >
        -minutesOffSet;
      setCanJoin(showJoinCourse);
    }
  }, [time]);

  return canJoin;
};
