import { Row } from "../Layout";
import styled from "styled-components";
import { Paragraph2 } from "../Typography";
import { convertHexToRGBA } from "../../_utils/color";

export const CardBasicForm = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.palette.neutralWhite};
  border-radius: ${({ theme }) => theme.components.card.borderRadius}px;
  box-shadow: ${({ theme }) => theme.components.card.boxShadow};
  border: none;
  display: flex;
  flex-direction: ${({ type }) => (type === "left-right" ? "row" : "column")};
  width: ${({ width }) => width};
  padding: ${({ $p, theme }) => ($p ? $p : theme.components.card.padding)}px;
`;

export const CircleButton = styled.div`
  background: ${({ theme }) => theme.palette.primaryBlue};
  border-radius: 50%;
  padding: 14px;
  margin-left: 8px;
  cursor: pointer;
`;

export const CenteredRow = styled(Row)`
  align-items: center;
  margin-left: 0;
  margin-right: 0;
`;

export const ClickableRow = styled(Row)`
  cursor: pointer;
`;

export const HeaderContent = styled(CenteredRow)`
  justify-content: space-between;
`;

export const HeaderText = styled(Paragraph2)`
  color: ${({ theme }) => theme.palette.level1Green};
  font-weight: 700;
`;

export const ProgressContent = styled(CenteredRow)`
  margin-top: ${({ theme }) => theme.components.card.padding}px;
`;

export const ProgressView = styled.div`
  width: ${({ theme }) => theme.components.card.progressSize}px;
  height: ${({ theme }) => theme.components.card.progressSize}px;
  margin-right: 8px;
`;

export const ProgressText = styled(Paragraph2)`
  color: ${({ theme }) => theme.palette.primaryBlue};
  font-weight: 700;
`;

export const BottomContent = styled(CenteredRow)`
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.components.card.padding}px;
  flex: 1;
  align-items: flex-end;
`;

export const BottomDateText = styled(Paragraph2)`
  color: ${({ theme }) => theme.palette.primaryBlue};
  font-weight: 700;
  margin-left: 4px;
`;

export const BarChartContainer = styled.div`
  margin-top: ${({ theme }) => theme.components.card.padding}px;
`;

export const BarChartLabel = styled(Paragraph2)`
  color: ${({ theme }) => theme.palette.neutralDarkerGrey};
  font-weight: 500;
`;

export const BarChartContent = styled.div`
  background: ${({ theme }) => convertHexToRGBA(theme.palette.level1Green, 20)};
  width: 100%;
  height: 16px;
  border-radius: 128px;
  margin-top: ${({ theme }) => theme.components.card.spacing}px;
`;

export const BarChartLine = styled.div`
  background: ${({ theme }) => theme.palette.level1Green};
  height: 16px;
  width: ${({ value }) => value}%;
  border-radius: 128px;
  margin-right: auto;
`;

export const customBuildStyles = (theme, type = "progress") => {
  const color = type === "progress" ? theme.palette.level1Green : theme.palette.primaryBlue;
  return {
    strokeLinecap: "butt",
    strokeWidth: 1,
    pathColor: color,
    trailColor: convertHexToRGBA(color, 20),
  };
};

const CardStyle = {
  BarChartContainer: BarChartContainer,
  BarChartContent: BarChartContent,
  BarChartLabel: BarChartLabel,
  BarChartLine: BarChartLine,
  BottomContent: BottomContent,
  BottomDateText: BottomDateText,
  CenteredRow: CenteredRow,
  CircleButton: CircleButton,
  HeaderContent: HeaderContent,
  HeaderText: HeaderText,
  ProgressContent: ProgressContent,
  ProgressText: ProgressText,
  ProgressView: ProgressView,
};

export default CardStyle;
