import { memo, useState, useEffect, useMemo } from "react";
import { isIOS, isMobile } from "react-device-detect";
import Card from "react-bootstrap/Card";
import styled, { css } from "styled-components";
import DatePill from "./_components/DatePill";
import BrandPlaceHolder from "../BrandPlaceHolder";
import { Col, Heading6, Icon, Paragraph4, Row } from "..";
import { level1Green } from "../../_theme/colors";
import PreviewVideo from "../Video/PreviewVideo";

// use image="None" if you want a card w/o image
// Placeholder images are taken care of if image=null or image breaks

const highlights = ["featured", "promoted", "staff-pick"];

const CardBase = ({
  children,
  bgColor,
  id,
  image,
  type = "top-down",
  forceRadius = false,
  color,
  contentColor = "#FFFFFF",
  overlay,
  overlayTop,
  overlayCentered,
  onClick,
  pillData,
  slider,
  width,
  topBar,
  noShadow = false,
  flipped = false,
  flippedContent,
  highlight,
  videoSrc,
  customThumbnail,
  ...rest
}) => {
  const showHighlight = highlights.indexOf(highlight) > -1 ? highlight : null;
  return (
    <>
      {pillData?.show && <DatePill data={pillData} />}
      <FrontOfCard $flipped={flipped}>
        <StyledCard
          type={type}
          $width={width}
          onClick={onClick}
          $slider={slider}
          id={id}
          $bgColor={bgColor}
          $noShadow={noShadow}
        >
          {showHighlight && (
            <PromoBanner>
              <Icon name="star" height="16px" width="16px" />
              <Paragraph4 weight={900} $pl={2} cursor="pointer">
                {highlight}
              </Paragraph4>
            </PromoBanner>
          )}
          {topBar && <TopBar>{topBar}</TopBar>}
          {type !== "left-right" && !isIOS && Boolean(videoSrc) ? (
            <>
              <PreviewVideo
                src={videoSrc}
                poster={image}
                customThumbnail={customThumbnail}
                overlay={overlay}
                overlayCentered={overlayCentered}
                overlayTop={overlayTop}
                {...rest}
              />
            </>
          ) : (
            <ImageSection
              image={image}
              type={type}
              noChildren={!children}
              overlay={overlay}
              overlayCentered={overlayCentered}
              overlayTop={overlayTop}
              customThumbnail={customThumbnail}
              {...rest}
            />
          )}
          <BodyContent
            color={contentColor}
            $type={type}
            $zeroTopBorderRadius={type === "top-down" && !forceRadius}
            image={image}
          >
            {children}
          </BodyContent>
          {type === "left-right" && (
            <ImageSection
              image={image}
              type={type}
              noChildren={!children}
              overlay={overlay}
              overlayCentered={overlayCentered}
              {...rest}
            />
          )}
        </StyledCard>
      </FrontOfCard>
      {(flippedContent?.body || flippedContent) && (
        <BackOfCard $flipped={flipped}>
          <StyledCard
            type={type}
            $width={width}
            onClick={onClick}
            $slider={slider}
            id={`${id || ""}-back_of_card`}
            $bgColor={bgColor}
            $noShadow={noShadow}
          >
            <FlippedBody justify="center" px={2}>
              <ConfirmationIcon
                name={flippedContent?.icon ? flippedContent.icon : "check-circle"}
                fill={flippedContent?.fill ? flippedContent.fill : level1Green}
                height="3em"
                width="3em"
              />
              <Col xs={12}>
                <ConfirmationText weight={700} my={4} textAlign="center">
                  {flippedContent.body || flippedContent}
                </ConfirmationText>
              </Col>
            </FlippedBody>
          </StyledCard>
        </BackOfCard>
      )}
    </>
  );
};

const FlippedBody = styled(Row)`
  height: 100%;
  min-height: 300px;
`;

const ConfirmationIcon = styled(Icon)`
  margin-top: auto;
`;

const ConfirmationText = styled(Heading6)`
  max-width: 250px;
  margin: auto;
`;

const FrontOfCard = styled.div`
  height: 100%;
  margin: 10px;
  transform: ${({ $flipped }) => ($flipped ? "rotateY(90deg)" : "rotateY(0deg)")};
  transition: all ease-in 0.3s;
  transition-delay: ${({ $flipped }) => ($flipped ? "0s" : "0.3s")};
`;

const BackOfCard = styled.div`
  display: flex;
  margin: 10px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  transform: ${({ $flipped }) => ($flipped ? "rotateY(0deg)" : "rotateY(90deg);")};
  transition: all ease-in 0.3s;
  transition-delay: ${({ $flipped }) => ($flipped ? "0.3s" : "0s")};
`;

const ImageSection = ({
  image,
  type,
  noChildren,
  overlay,
  overlayTop,
  overlayCentered,
  customThumbnail,
  ...rest
}) => {
  const [srcImage, setSrcImage] = useState(null);

  useEffect(() => {
    if (customThumbnail ?? image) {
      setSrcImage(customThumbnail ?? image);
    }
  }, [image, customThumbnail]);

  const isPlainBackground = useMemo(
    () => ["live", "challenge", "tc"].includes(srcImage) && srcImage,
    [srcImage]
  );
  if (srcImage === "None" || srcImage === "Custom") return null;

  return (
    <ImageWrapper
      $color={isPlainBackground}
      $type={type}
      $mobileSafari={isPlainBackground && isIOS && isMobile}
    >
      {!["live", "challenge", "tc"].includes(srcImage) && srcImage ? (
        <Image
          variant="top"
          $type={type}
          alt=""
          src={srcImage}
          onError={() => setSrcImage(null)}
          $noChildren={noChildren}
        />
      ) : ["live", "challenge", "tc", "None"].includes(srcImage) ? null : (
        <BrandPlaceHolder />
      )}
      {overlayTop && <Overlay $top={0}>{overlayTop}</Overlay>}
      {overlay && <Overlay {...rest}>{overlay}</Overlay>}
      {overlayCentered && <OverlayCentered>{overlayCentered}</OverlayCentered>}
    </ImageWrapper>
  );
};

export const StyledCard = styled(Card)`
  position: relative;
  flex: 1;
  height: 100%;
  background: ${({ theme, $bgColor }) =>
    $bgColor === "blue" ? theme.palette.tagBlue : $bgColor ? $bgColor : theme.palette.neutralWhite};
  box-shadow: ${({ $noShadow, $slider }) =>
    $noShadow
      ? "none"
      : $slider
      ? "0px 4px 6px rgba(0, 0, 0, 0.12)"
      : "0px 8px 12px rgba(0, 0, 0, 0.12)"};
  border-radius: ${({ theme }) => theme.components.card.borderRadius}px;
  border: none;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "auto")};
  display: flex;
  flex-direction: ${({ type }) => (type === "left-right" ? "row" : "column")};
  max-width: ${({ $width }) => $width};
  transition: all 250ms ease-in-out;
  &:hover {
    box-shadow: ${({ $noShadow, $slider }) =>
      $noShadow
        ? "none"
        : $slider
        ? "0px 6px 10px rgba(0, 0, 0, 0.17)"
        : "0px 18px 24px rgba(0, 0, 0, 0.17)"};
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-top: ${({ $mobileSafari }) => $mobileSafari && "56.25%"};
  aspect-ratio: 16/9;
  background-color: ${({ theme, $color }) =>
    !$color ? "" : $color === "tc" ? theme.palette.tagRed : theme.palette.tagBlue};
  border-top-left-radius: ${({ theme }) => `${theme.components.card.borderRadius}px`};
  border-top-right-radius: ${({ theme }) => `${theme.components.card.borderRadius}px`};
`;

const Image = styled(Card.Img)`
  border-top-left-radius: ${({ theme, $type }) =>
    $type === "top-down" ? theme.components.card.borderRadius : 0}px;
  border-top-right-radius: ${({ theme }) => theme.components.card.borderRadius}px;
  border-bottom-left-radius: ${({ theme, $noChildren }) =>
    $noChildren && theme.components.card.borderRadius}px;
  border-bottom-right-radius: ${({ theme, $noChildren, $type }) =>
    $type === "left-right" || $noChildren ? theme.components.card.borderRadius : 0}px;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 16/9;
  width: 100%;
  height: 100%;
`;

const BodyContent = styled.div`
  background: ${({ theme, color }) =>
    typeof color === "boolean" && color ? theme.palette.tagBlue : color};
  padding: ${({ theme, image }) => (image === "Custom" ? 0 : theme.components.card.padding)}px;
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  flex-grow: 1 !important;
  border-top-left-radius: ${({ theme, $zeroTopBorderRadius }) =>
    $zeroTopBorderRadius ? "0" : theme.components.card.borderRadius}px;
  border-top-right-radius: ${({ theme, $zeroTopBorderRadius }) =>
    $zeroTopBorderRadius ? "0" : theme.components.card.borderRadius}px;
  border-bottom-right-radius: ${({ theme }) => theme.components.card.borderRadius}px;
  border-bottom-left-radius: ${({ theme, type, forceRadius }) =>
    type === "left-right" && !forceRadius ? 0 : theme.components.card.borderRadius}px;
`;

const borderTopRadius = css`
  border-top-left-radius: ${({ theme }) => theme.components.card.borderRadius}px;
  border-top-right-radius: ${({ theme }) => theme.components.card.borderRadius}px;
`;

const Overlay = styled.div`
  ${borderTopRadius}
  position: absolute;
  top: ${(props) => props.$top};
  right: ${(props) => props.$right};
  bottom: ${(props) => props.$bottom};
  left: ${(props) => props.$left};
  width: 100%;
  height: unset;
`;

const OverlayCentered = styled.div`
  ${borderTopRadius}
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const OverlayWrapper = styled(Card.ImgOverlay)`
  border-radius: 24px;
  z-index: 1;
`;

const TopBar = styled.div`
  ${borderTopRadius}
  overflow: hidden;
`;

const PromoBanner = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 1rem;
  background-color: ${({ theme }) => theme.palette.orangeStar};
  padding: 0.8rem;
  border-radius: 0 0px 12px 12px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

export const CardOverlay = ({ children }) => {
  return <OverlayWrapper>{children}</OverlayWrapper>;
};

export default memo(CardBase);
