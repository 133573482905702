import {
  // FINDER_REQUEST,
  // FINDER_SUCCESS,
  // FINDER_FAILURE,
  FINDER_SET_VALUES,
  FINDER_RESET,
  FINDER_SHOW_ON_MOBILE,
} from "../../reducers/search/finder";

export const finderShowOnMobile = (showFinderOnMobile) => ({
  type: FINDER_SHOW_ON_MOBILE,
  showFinderOnMobile,
});

// Unused finder actions, add back in if needed
// const finderRequest = () => ({ type: FINDER_REQUEST });
// const finderFailure = (errors) => ({ type: FINDER_FAILURE, errors });
// const finderSuccess = (payload) => ({ type: FINDER_SUCCESS, payload });

export const finderSetValues = (values) => ({
  type: FINDER_SET_VALUES,
  values,
});

export const finderReset = () => ({ type: FINDER_RESET });
