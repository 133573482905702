import React, { memo } from "react";
import { Controller } from "react-hook-form";
import FormGroup from "./FormGroup";
import Dropdown from "../Dropdown";

const FormSelect = ({
  control,
  label,
  name,
  helperText,
  helperColor,
  disabled,
  isMulti,
  required,
  error,
  errors,
  rules,
  defaultValue = "",
  onChange,
  ...rest
}) => {
  return (
    <FormGroup
      label={label}
      name={name}
      helperText={helperText}
      helperColor={helperColor}
      required={required || rules?.required}
      errors={errors}
      error={error}
    >
      {control ? (
        <Controller
          as={Dropdown}
          aria-label={name}
          closeMenuOnSelect={isMulti ? false : true}
          control={control}
          defaultValue={defaultValue}
          id={name}
          isDisabled={disabled}
          isMulti={isMulti}
          name={name}
          required={required}
          rules={rules}
          {...rest}
        />
      ) : (
        <Dropdown
          aria-label={name}
          closeMenuOnSelect={isMulti ? false : true}
          defaultValue={defaultValue}
          id={name}
          isDisabled={disabled}
          isMulti={isMulti}
          name={name}
          required={required}
          onChange={(e, action) => onChange && onChange(e, action)}
          {...rest}
        />
      )}
    </FormGroup>
  );
};

export default memo(FormSelect);
