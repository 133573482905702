export const UI_CURRENT_SIDENAV = "ui/CURRENT_SIDENAV";

const savedValue = localStorage.getItem("ba-sidenav");

const initialState = {
  current: savedValue !== "undefined" ? savedValue : "wellness-habits",
};

export function sidenav(state = initialState, action) {
  const { current } = action;
  localStorage.setItem("ba-sidenav", current);
  switch (action.type) {
    case UI_CURRENT_SIDENAV:
      return {
        current,
      };
    default:
      return state;
  }
}

export default sidenav;
