import { memo, useContext } from "react";
import BarChart from "./BarChart";
import { ThemeContext } from "styled-components";

const TeamChallengeLeaderboardBarChart = ({ challenge, isTC, title = true, id }) => {
  const theme = useContext(ThemeContext);
  const valueField = challenge?.metric !== "points" ? `${challenge?.metric}_taken` : "points";
  let value = 40;
  if (challenge?.target) {
    value = Math.floor((challenge[valueField] / challenge?.target) * 100);
  } else if (challenge[valueField] === 0) {
    value = 0;
  }
  if (challenge?.method && challenge?.method === "highest") {
    value = challenge?.highestProgressDisplay || 0;
  }
  return (
    <>
      <BarChart
        id={id}
        color={theme.palette.tagBlue}
        alignLabel={isTC ? "space-between" : "flex-end"}
        lineValue={value > 100 ? 100 : value}
      />
    </>
  );
};

export default memo(TeamChallengeLeaderboardBarChart);
