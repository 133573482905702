import styled from "styled-components";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

import { Heading5 } from "../Typography";
import CardBase from "./CardBase";
import {
  HeaderOverlay,
  HeaderOverlayTopClassTime,
  InstructorCardRow,
  LevelRating,
  LiveButton,
  LiveClassOverlay,
  CourseStatusOverlay,
  LeaveReview,
} from "./_components";
import { CategoriesMap } from "../Badge";
import { lineClamp, ellipsis } from "../../_theme/general";

import { utcDateToDisplay } from "../../_utils";
import { ROUTES } from "../../_routes";
import { useProgramCourseStatus } from "./_utils";

const ClassCard = ({
  data,
  displayIndex,
  enrolled, // this is specific to enrolled in programs
  identifier,
  onClick,
  parentPage,
  type,
  noSchedule,
  scheduleCallback,
  favoriteCallback,
  hideFavorite,
  openCalendarsModal,
  ...rest
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { missed, completed, time } = useProgramCourseStatus(type, data, enrolled);

  const live = Boolean(
    data?.type === "live" ||
      data?.type === "live_open_door" ||
      (data?.type === "live-video" && data?.schedule?.id)
  );
  const date = live ? utcDateToDisplay(data?.schedule?.date_schedule) : time;
  const srcImage = (live && "live") || data?.file_poster;

  const handleOnClick = () => {
    if (noSchedule && live) return;
    let pathname = live
      ? `${ROUTES.COURSE}/${data.id}${ROUTES.LIVE}/${data?.schedule?.id}`
      : `${ROUTES.COURSE}/${data?.id}`;
    const breadcrumbs = location.pathname?.split("/") || [];

    // If programTitle/playlistTitle exist, add it to the breadcrumbs
    // 'programTitle'/'playlistTitle' is used to indicate where to grab the program title from
    if (rest?.programTitle) breadcrumbs.push("programTitle", rest.programTitle);
    if (rest?.playlistTitle) breadcrumbs.push("playlistTitle", rest.playlistTitle);

    navigate(pathname, {
      state: { from: parentPage, identifier, displayIndex, breadcrumbs },
    });
  };

  if (!data) return null;

  return (
    <CardBase
      $bottom={0}
      $right={0}
      overlay={<HeaderOverlay live={live} data={data} />}
      overlayTop={
        <HeaderOverlayTopClassTime
          live={live}
          data={data}
          hideFavorite={hideFavorite}
          favoriteCallback={favoriteCallback}
        />
      }
      overlayCentered={<LiveClassOverlay live={live} data={data} {...rest} />}
      onClick={handleOnClick}
      image={srcImage}
      videoSrc={data?.file_preview || null}
      customThumbnail={data?.custom_thumbnail}
      pillData={{
        show: Boolean(data?.date_scheduled && enrolled),
        missed: missed,
        complete: completed,
        date: moment(date).format(live ? "ddd, M/D • h:mm A" : "ddd, M/D"),
      }}
      {...rest}
      type="top-down"
      highlight={data?.highlight}
    >
      <CourseStatusOverlay data={data} enrolled={enrolled} scheduled={time} />
      <LevelRating data={data} />
      <TitleContent
        $amt={2}
        weight="700"
        title={data?.title}
        tabIndex={0}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            handleOnClick();
          }
        }}
      >
        {data?.title}
      </TitleContent>

      <CategoriesMap
        data={data?.categories}
        limit={4}
        $mb={3}
        badgeEntityId={`class-card-${data?.id || ""}-schedule-id-${
          data?.schedule?.id || null
        }-created_at-${
          data?.created_at || null
        }-parent-page-${parentPage}-day-course-in-program-is-scheduled-
        ${type === "program" ? data?.date_scheduled : ""}`}
      />
      <InstructorCardRow data={data} />
      <LeaveReview data={data} />
      {!noSchedule && (
        <LiveButton
          course={data}
          live={live}
          state={{ from: parentPage, identifier, displayIndex }}
          callback={scheduleCallback}
          openCalendarsModal={openCalendarsModal}
        />
      )}
    </CardBase>
  );
};

export const TitleContent = styled(Heading5)`
  ${lineClamp}
  ${ellipsis}
  height: 2.6em;
  margin-bottom: 1rem;
`;

export default ClassCard;
