import React, { memo } from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { noMargin, noPadding } from "../../_theme/general";
import Button from "../Buttons";

const IconButton = React.forwardRef(
  (
    { label, onClick, name, text, size, height, width, fill, stroke, circle, type, ...props },
    ref
  ) => (
    <Wrapper ref={ref} {...props} onClick={onClick} $circle={circle} {...props}>
      <Icon name={name} height={height || size} width={width || size} fill={fill} stroke={stroke} />
      {text ? (
        <Button id={label || null} type={type || null} bType="outline">
          {text}
        </Button>
      ) : null}
    </Wrapper>
  )
);

const Wrapper = styled.div`
  position: ${({ $absolute }) => ($absolute ? "absolute" : "")};
  top: ${({ $top }) => $top};
  right: ${({ $right }) => $right};
  bottom: ${({ $bottom }) => $bottom};
  left: ${({ $left }) => $left};
  background-color: ${({ $circle, theme }) => $circle && theme.palette.primaryBlue};
  border-radius: ${({ $circle }) => $circle && "50%"};
  ${noMargin}
  ${noPadding}
  &:hover {
    cursor: pointer;
  }
`;

export default memo(IconButton);
