import { useCallback, useEffect, useState } from "react";
import geApi, { getGeHeaders, getGeJwt } from "../_utils/geApi";
import { ApiResponse, EndpointResponse, Streak, UserMe, UserStats } from "ge_api";
import { useQuery } from "@tanstack/react-query";

export const useGeJwt = () => {
  const jwt = getGeJwt();
  const headers = getGeHeaders(jwt);

  return {
    jwt,
    headers,
  };
};

export function useGeApi<T>(endpoint: string, params?: object, method = "get"): ApiResponse<T> {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [{ endpoint, method, params }],
    queryFn: async () => {
      const api = geApi(endpoint);
      const response = await api[method](params);
      return response.data as T;
    },
    refetchOnWindowFocus: true,
    staleTime: 5000,
  });
  return { data, isLoading, error, refetch };
}

export function useGeEndpoint<T>(endpoint: string, params?: object) {
  const { data, isLoading, error, refetch } = useGeApi<EndpointResponse<T>>(endpoint, params);

  const paginationSchema = {
    page: 0,
    per_page: 0,
    results: [],
    total: 0,
    total_pages: 0,
  };

  const { page, per_page, results = [], total, total_pages } = data || paginationSchema;

  return {
    data: results,
    refetch,
    error,
    isLoading,
    page,
    per_page,
    total,
    total_pages,
  };
}

export function useGeEndpointInfinite<T>(endpoint: string, initialParams?: object) {
  const [params] = useState(initialParams);
  const [currentPage, setCurrentPage] = useState(1);
  const [accumulatedData, setAccumulatedData] = useState<T[]>([]);

  const { data, isLoading, error, total, total_pages, page, per_page } = useGeEndpoint<T>(
    endpoint,
    {
      ...params,
      page: currentPage,
    }
  );

  useEffect(() => {
    if (data.length > 0) {
      setAccumulatedData((prevData) => [...prevData, ...data]);
    }
  }, [data]);

  const loadMore = useCallback(() => {
    console.log("loadMore", currentPage, total_pages);
    if (currentPage < total_pages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [currentPage, total_pages]);

  const hasMore = currentPage < total_pages;

  return {
    data: accumulatedData,
    isLoading,
    error,
    total,
    total_pages,
    page,
    per_page,
    loadMore,
    hasMore,
  };
}

export const useGeStreaks = (params?: object) => {
  const api = useGeApi<UserMe>("users/me", params);
  const streaks = api.data?.progress?.goals?.streak || [];
  const findStreakById = (id: number): Streak =>
    streaks?.find((streak: Streak) => streak.goal_id === id) as Streak;

  // TODO: move hardcode ids out of codebase
  // get from brand config instead
  const { featured_streak_ids } = {
    featured_streak_ids: {
      day: 1,
      week: 2,
      month: 3,
    },
  };

  const featured = Object.entries(featured_streak_ids);

  return {
    ...api,
    streaks,
    findStreakById,
    featured,
    featured_streak_ids,
  };
};

export const useGePrimerStatus = () => {
  const me = useGeApi<UserMe>("users/me");
  const { is_seen_primer, is_seen_points_primer } = me.data?.settings || {
    is_seen_primer: true,
    is_seen_points_primer: true,
  };

  const showPrimer = !is_seen_primer;
  const showPoints = !showPrimer && !is_seen_points_primer;
  const enablePoints = showPrimer && !is_seen_points_primer;

  return {
    showPoints,
    showPrimer,
    enablePoints,
  };
};

export const useGePoints = (params?: object) => {
  const me = useGeApi<UserMe>("users/me", params);
  const points = me.data?.stats?.BrandStat_1 || ({} as UserStats);
  return {
    ...me,
    points,
  };
};
