import styled from "styled-components";
import { baseCard } from "../../_theme/general";
import { Row, Col, Heading4, Icon } from "..";

const UserPreferencesCard = ({
  data,
  handleOnClick,
  selected,
  icon,
  height = 88,
  ariaLabel,
  id,
}) => {
  return (
    <WrapperCard
      aria-label={ariaLabel || null}
      onClick={handleOnClick}
      $selected={selected}
      $height={height}
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          handleOnClick();
        }
      }}
      id={`${data.title.replace(/[^0-9a-zA-Z]/g, "-")}-${data.id}` || id || null}
    >
      <Col $px={0} $py={0}>
        <TitleWrapper color={selected ? "white" : "primary"} cursor="pointer">
          {data?.title}
        </TitleWrapper>
      </Col>
      {icon && (
        <IconWrapper xs="auto">
          <Icon fill={selected ? "white" : "primary"} name={icon} />
        </IconWrapper>
      )}
    </WrapperCard>
  );
};

const WrapperCard = styled(Row)`
  ${baseCard};
  background-color: ${({ theme, $selected }) => $selected && theme.palette.primaryBlue} !important;
  width: 100%;
  align-self: center;
  height: ${({ $height }) => $height}px;
  cursor: pointer;
  flex: ${({ $height }) => ($height ? "unset" : 1)};
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const TitleWrapper = styled(Heading4)`
  @media screen and (max-width: 768px) {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }
`;

const IconWrapper = styled(Col)`
  justify-content: flex-end;
  margin-left: auto;
  padding: 0;
`;

export default UserPreferencesCard;
