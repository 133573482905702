import { useEffect, useState, useCallback } from "react";
import authAxios from "../_utils/authAxios";

export const useGetFollows = (item, card = true) => {
  const [follows, setFollows] = useState(null);
  const [aria, setAria] = useState(null);
  const [followers, setFollowers] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    if (item) {
      setFollows(item?.follows ?? item);
    }
  }, [item]);

  useEffect(() => {
    if (follows) {
      const textIsFollowing = follows?.follow_status === "active";
      const fText = textIsFollowing ? "Unfollow" : "Follow";
      const iText = !item?.first_name ? "Insturctor" : `${item?.first_name} ${item?.last_name}`;
      const text = `Click to ${fText} ${iText}`;
      setAria(text);
      const followText =
        follows?.follow_count > 1 || follows?.follow_count === 0 ? "followers" : "follower";
      setFollowers(followText);
      setIsFetching(false);
    }
  }, [follows, item]);

  const handleFollowClick = useCallback(
    (e) => {
      e?.stopPropagation();
      setIsFetching(true);
      authAxios
        .get(follows?.follow_api)
        .then((response) => {
          if (response.status === 200) {
            setFollows(response.data.data);
            const text =
              item?.follows?.follow_count > 1 || item?.follows?.follow_count === 0
                ? "followers"
                : "follower";
            setFollowers(text);
          }
        })
        .finally(() => setIsFetching(false));
    },
    [follows?.follow_api, item?.follows?.follow_count]
  );

  return {
    follow: {
      aria: aria,
      api: follows?.follow_api,
      count: follows?.follow_count,
      text: followers,
      following: follows?.follow_status === "active",
      loading: isFetching,
    },
    handleFollowClick,
  };
};

export default useGetFollows;
