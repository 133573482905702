import React, { memo } from "react";

import { Icon } from "./Icon";

const IconCircle = ({
  children,
  icon,
  label,
  iconSize = "1.7em",
  iconClass = "icon-circle-programs",
  textClass = "small",
}) => {
  return (
    <div className="d-flex">
      {icon ? (
        <div className={`${iconClass} mr-2 text-center `}>
          <Icon height={iconSize} width={iconSize} name={icon} />
        </div>
      ) : null}
      {label ? (
        <div className={textClass}>
          <div className="text-muted-darker mb-1">{label}</div>
          {children}
        </div>
      ) : null}
    </div>
  );
};

export default memo(IconCircle);
