export const SCHEDULE_LOG = "SCHEDULE_LOG";
export const SCHEDULE_RESET = "SCHEDULE_RESET";

const initialState = {
  dismissed: [],
};

export const schedule = (state = initialState, action) => {
  switch (action.type) {
    case SCHEDULE_LOG:
      return {
        ...state,
        ...action,
      };
    case SCHEDULE_RESET:
      return initialState;
    default:
      return state;
  }
};

export default schedule;
