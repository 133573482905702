import { memo } from "react";

export const challengeDescText = ({ challenge }) => {
  const method =
    challenge?.challenge?.method || challenge?.teamchallenge?.method || challenge?.method;
  const target =
    challenge?.challenge?.target || challenge?.teamchallenge?.target || challenge?.target;
  const metric =
    challenge?.challenge?.metric || challenge?.teamchallenge?.metric || challenge?.metric;
  const habit = challenge?.challenge?.habit?.title || challenge?.habit?.title || "";
  const habitsMetric =
    challenge?.challenge?.habit?.group?.title === "Physical activities" ? "minutes" : "counts";

  const metricTitleMapper = (metric) => {
    const metricOptions = {
      wellness_habit: "wellness habit",
      points: "Burnalong points",
      burnalong_points: "Burnalong points",
      steps: "steps",
      minutes: "minutes",
      classes: "Burnalong classes",
    };
    return metricOptions[metric];
  };

  // Fastest == Goal
  // Classes Metric Description
  if (metric === "classes") {
    // Goal
    if (method === "fastest") return `Reach goal of ${target} ${metricTitleMapper(metric)}`;
    // Highest
    else return `Take the most Burnalong Classes`;
  }
  // Steps, Minutes, Points, Burnalong Points Metric Description
  else if (
    metric === "steps" ||
    metric === "minutes" ||
    metric === "points" ||
    metric === "burnalong_points"
  ) {
    // Goal
    if (method === "fastest") return `Reach goal of ${target} ${metricTitleMapper(metric)}`;
    // Highest
    else return `Get the most ${metricTitleMapper(metric)}`;
  }
  // Wellness Habit Metric Description
  else if (metric === "wellness_habit") {
    if (method === "fastest") return `Reach goal of ${target} ${habitsMetric} in ${habit}`;
    else return `Reach goal of ${target} ${metricTitleMapper(metric)}`;
  } else return "";
};

const ChallengeTargetDescription = ({ challenge }) => {
  return challengeDescText({ challenge });
};

export default memo(ChallengeTargetDescription);
