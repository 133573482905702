import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Image from "react-bootstrap/Image";
import { noMargin, noPadding } from "../../_theme/general";
import baLogo from "../../_media/images/ba_icon_white.png";

const BaseSkeleton = ({ overlay, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <SkeletonTheme color="#cccccc" highlightColor="#d9d9d9">
        <Skeleton {...rest} />
        {overlay && (
          <Overlay>
            <Image src={baLogo} />
          </Overlay>
        )}
      </SkeletonTheme>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${noMargin}
  ${noPadding}
  position: relative;
  cursor: wait;

  & .react-loading-skeleton {
    border-radius: ${({ card }) => card && "24px"};
    border-radius: ${({ circle }) => circle && "50%"};
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0.3;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default BaseSkeleton;
