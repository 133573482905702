import styled from "styled-components";
import { ThemeContext } from "styled-components";
import { memo, useContext } from "react";
import { Helmet } from "react-helmet";
import { useIsMounted } from "../_hooks";
import { Container, Col, Row } from "../_components/Layout";
import { Heading1, Heading4, Heading6 } from "../_components/Typography";
import { GoogleTranslate } from "../_components/GoogleTranslate";
import { Copyright, legalLinks } from "./Shell/Footer";
import LOGO from "../_media/images/burnalong-wordmark-masterBlue.png";
import { Hyperlink } from "../_components/ui";

export const NonAuthLayout = memo(({ title, docTitle, subtitle, children }) => {
  const theme = useContext(ThemeContext);
  const isMounted = useIsMounted();

  if (!isMounted) return null;
  return (
    <Wrapper fluid>
      <Helmet>
        <title>{docTitle || title}</title>
      </Helmet>
      <RowWrapper>
        <BrandBand sm={12} md={3}>
          <InnerColumn>
            <Image alt="Burnalong Logo" src={LOGO} />
            <BrandHeading>Thrive Together</BrandHeading>
          </InnerColumn>
          <StickyView>
            <GoogleTranslate color={theme.palette.primaryBlue} />
          </StickyView>
        </BrandBand>
        <Col sm={12} md={9}>
          <Row style={{ margin: "auto 0" }}>
            <MainContentWrapper xs={12}>
              <Heading1 weight="700" $mb={4} textAlign="center">
                {title}
              </Heading1>
              {subtitle && <Heading6 weight="700">{subtitle}</Heading6>}
              {children}
            </MainContentWrapper>
          </Row>
          <Row $justify="space-between" $pb={2}>
            <div>
              <Col>
                {[...legalLinks].map((item) => {
                  return (
                    <Hyperlink
                      key={item.link}
                      href={item.link}
                      target={item.target}
                      className="mb-3"
                    >
                      {item.label}
                    </Hyperlink>
                  );
                })}
              </Col>
            </div>
            <Copyright />
          </Row>
        </Col>
      </RowWrapper>
    </Wrapper>
  );
});

const Wrapper = styled(Container)`
  padding: 0;
  background-color: ${({ theme }) => theme.palette.neutralWhite};
`;

const MainContentWrapper = styled(Col)`
  padding: 3rem;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    padding: 2rem 0.5rem;
    align-items: center;
    justify-content: center;
  }
`;

const RowWrapper = styled(Row)`
  flex: 1 1 auto;
`;

const StickyView = styled.div`
  position: sticky;
  z-index: 1001;
  bottom: 30px;
`;

const BrandBand = styled(Col)`
  background-color: ${({ theme }) => theme.palette.baseTeal};
  padding: 2rem;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 0.25rem;
  }
`;

const InnerColumn = styled(Col)`
  align-items: "flex-start";
  justify-content: center;
  @media screen and (max-width: 768px) {
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }
`;

const Image = styled.img`
  display: block;
  max-width: 200px;
  height: auto;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */

  @media screen and (min-width: 768px) and (max-width: 992px) {
    max-width: 150px;
  }
`;

const BrandHeading = styled(Heading4)`
  font-weight: 700;
  @media screen and (min-width: 768px) and (max-width: 992px) {
    font-size: 20px;
  }
`;

export default NonAuthLayout;
