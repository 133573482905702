import ReactPaginate from "react-paginate";
import styled from "styled-components";
import { Row } from "../_components/Layout";
import Icon from "../_components/Icon";

const buildPageLabel = (pageNum) => {
  let numberToRender = pageNum;
  if (numberToRender.toString().length === 1) {
    numberToRender = "00" + pageNum;
  } else if (numberToRender.toString().length === 2) {
    numberToRender = "0" + pageNum;
  }
  return <span>{numberToRender}</span>;
};

const Pagination = (props) => {
  const returnPageNumber = (e) => {
    const pageNumber = e.selected + 1;
    props.onPageChange(pageNumber);
  };

  return props.pageCount > 1 ? (
    <PaginationRow>
      <StyledPaginateContainer>
        <ReactPaginate
          previousLabel={
            <LeftArrowIcon
              // activepage and pagecount must be lowercase as per react
              activepage={props.activePage}
              pagecount={props.pageCount}
              height={24}
              name="chevron-left"
            />
          }
          nextLabel={
            <RightArrowIcon
              // activepage and pagecount must be lowercase as per react
              activepage={props.activePage}
              pagecount={props.pageCount}
              height={24}
              name="chevron-right"
            />
          }
          pageLabelBuilder={(pageNum) => buildPageLabel(pageNum)}
          breakLabel={<BreakSpan>...</BreakSpan>}
          breakClassName={"styled-break"}
          pageCount={props.pageCount}
          marginPagesDisplayed={props.marginPagesDisplayed || 0}
          pageRangeDisplayed={props.pageRangeDisplayed || 2}
          onPageChange={(e) => returnPageNumber(e)}
          containerClassName={"styled-pagination"}
          activeClassName={"styled-active"}
          forcePage={props.activePage - 1 || 0}
        />
      </StyledPaginateContainer>
    </PaginationRow>
  ) : null;
};

const PaginationRow = styled(Row)`
  flex: 1 1;
  margin-top: 2rem;
  margin-bottom: 2rem;
  justify-content: space-around;
`;

const StyledPaginateContainer = styled.div`
  .styled-pagination {
    position: relative;
    display: flex;
    flex: 1;
    align-self: flex-start;
    list-style: none;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    place-content: center;
    & span {
      padding: 8px 16px;
      border-radius: 50px;
      color: ${(props) => props.theme.palette.neutralDarkestGrey};
      background-color: ${(props) => props.theme.palette.neutralWhite};
      font-weight: 700;
    }
    & li {
      margin: 0.5rem 0.25rem;
      min-height: 36px;
    }
  }
  .styled-active {
    & span {
      background-color: #e6251e;
      color: white;
      box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.12);
      padding: 16px;
      border-radius: 50px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
`;

const LeftArrowIcon = styled(Icon)`
  position: absolute;
  top: calc(50% - 24px);
  font-size: 24px;
  display: ${(props) => (props.activepage === 1 ? "none" : null)};
  left: -2.5rem;
  @media screen and (max-width: 576px) {
    left: -1rem;
  }
`;

const RightArrowIcon = styled(Icon)`
  position: absolute;
  top: calc(50% - 24px);
  font-size: 24px;
  display: ${(props) => (props.activepage === props.pagecount ? "none" : null)};
  right: -2.5rem;
  @media screen and (max-width: 576px) {
    right: -1rem;
  }
`;

const BreakSpan = styled.span`
  font-size: 18px;
  font-weight: 700;
`;
export default Pagination;
