import { memo, Suspense, useEffect } from "react";
import { createBrowserRouter, Route, RouterProvider } from "react-router-dom";

// import AuthRoutes from "./AuthRoutes";
import PageLoader from "../_components/PageLoader";
import ScrollToTop from "../_components/ScrollToTop";

import { ROUTES } from "./constants";
import useWatchdog from "../Watchdog/useWatchdog";
import { authPssPaths, authUserPaths, nonAuthPaths } from "./paths";
import { initializeGA, trackPage } from "../_utils/analytics";

const routes = [...nonAuthPaths, ...authUserPaths, ...authPssPaths];
const router = createBrowserRouter(routes);

// Any time router state updates, we can add side effects
router.subscribe((state) => {
  trackPage(state.location.pathname);
});

const Routes = () => {
  useEffect(() => {
    initializeGA();
  }, []);

  useWatchdog();

  return (
    <Suspense fallback={<PageLoader height="100vh" bmascot />}>
      <RouterProvider router={router} fallbackElement={<PageLoader height="100vh" />}>
        <ScrollToTop />

        <Routes>
          {/* Errors */}
          {/* TODO remove, Legacy 404 page not reached anymore 
            ErrorBoundary is the new 404 page supported through errorElement on createBrowserRouter */}
          <Route path="*">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: "100%" }}
            >
              <h1>404 Error - Page not found :(</h1>
            </div>
          </Route>
        </Routes>
      </RouterProvider>
    </Suspense>
  );
};

export { Routes, ROUTES };

export default memo(Routes);
