import { useState, memo } from "react";
import styled from "styled-components";
import moment from "moment";
import { uniqueId } from "lodash";
import { useNavigate } from "react-router-dom";

import { Button, Row, Icon } from "../..";
import PersonalCalendarModal from "../../PersonalCalendarModal";

import { useLiveClassStatus } from "../_utils";
import { authAxios } from "../../../_utils";
import { ROUTES } from "../../../_routes";

const LiveButton = ({
  course,
  live = false,
  parentPage,
  callback,
  handleClassCancel,
  openCalendarsModal,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [scheduledClicked, setScheduledClick] = useState(false);
  const [showCalendarModal, setShowCalendarModal] = useState(false);

  const canJoin = useLiveClassStatus(course?.schedule?.date_schedule);
  const isOpenDoor = course?.type === "open_door";
  const todaysDate = moment();

  // isOngoing is missing date_eventends datapoint on MySchedule Page
  const isOngoing = moment(course?.schedule?.date_eventends).isAfter(todaysDate);
  // Difference between today (-10 minutes offset) and the date of the course
  const pastDue = todaysDate.diff(course?.schedule?.date_schedule) - 600000 > 0;

  if (!["live", "group", "solo", "open_door", "live_open_door"].includes(course?.type) && !live)
    return null;

  const handleScheduleClass = () => {
    setLoading(true);
    setScheduledClick(true);
    authAxios.post(`courses/liveschedule/${course?.schedule?.id}?type=enroll`).finally(() => {
      setLoading(false);
      callback && callback();
    });
  };

  const handleOnClick = (e) => {
    e.stopPropagation();
    if (canJoin) {
      let path =
        course?.type === "live" || course?.type === "live_open_door"
          ? `${ROUTES.COURSE}/${course.id}${ROUTES.LIVESTREAM}`
          : course?.type === "solo"
            ? `${ROUTES.COURSE}/${course?.id}${ROUTES.VIDEO}`
            : `${ROUTES.COURSE}${ROUTES.VIDEO}/${course?.url_slug}`;

      navigate(path, { state: { from: parentPage } });
    } else {
      setShowCalendarModal(true);
      handleScheduleClass();
    }
  };

  // const handleOpenCalendarsModal = (e) => {
  //   e.stopPropagation();
  //   openCalendarsModal?.(course);
  // };

  if (pastDue && !isOngoing) return null;
  return (course?.schedule_status && !canJoin) || scheduledClicked ? (
    <ScheduledRow>
      <PersonalCalendarModal
        course={course}
        show={showCalendarModal}
        setShow={setShowCalendarModal}
      />
      <ScheduleButton
        bType="outline"
        id={`${course?.id || null}-${uniqueId(course?.id)}${course?.type === "live" || course?.type === "live_open_door"
            ? `-scheduleId-${course?.schedule?.id}`
            : ``
          }-reserved-button`}
        onClick={handleClassCancel}
      >
        <Icon name="check-circle" fill="green" height="1em" />
        My Spot is Reserved!
      </ScheduleButton>
      {/* <IconButton
        name="calendar"
        fill="white"
        circle
        $ml={4}
        padding="8px"
        onClick={handleOpenCalendarsModal}
      /> */}
    </ScheduledRow>
  ) : (
    <Row $align="center" $justify="center">
      <PersonalCalendarModal
        course={course}
        show={showCalendarModal}
        setShow={setShowCalendarModal}
      />
      <ScheduleButton
        id={`${course?.id || null}-
        ${course?.schedule?.date_schedule || null}-join-button-${canJoin ? (isOpenDoor ? "hop-in" : "join") : "add-to-schedule"
          }-
        live-button-parentPage-${parentPage || null}
        `}
        onClick={handleOnClick}
        disabled={loading}
      >
        {canJoin ? (isOpenDoor ? "Hop in" : "Join") : "Add to Schedule"}
      </ScheduleButton>
      {/* {!canJoin && (
        <IconButton
          name="calendar"
          fill="white"
          circle
          $ml={4}
          padding="8px"
          onClick={handleOpenCalendarsModal}
        />
      )} */}
    </Row>
  );
};

const ScheduledRow = styled(Row)`
  height: ${({ theme }) => theme.components.button.heightNormal};
  justify-content: center;
  align-items: center;
`;

const ScheduleButton = styled(Button)`
  flex: 1;
  width: 100%;
`;

export default memo(LiveButton);
