import React, { useState } from "react";
import { uniqueId } from "lodash";
import styled from "styled-components";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import authAxios from "../_utils/authAxios";
import { ROUTES } from "../_routes";

import { Paragraph2, Row } from ".";
import Icon from "./Icon";
import OptionMenu from "./OptionMenu";
import SavePlaylistModal from "./SavePlaylistModal";

const hideFavorites = ["group", "live", "open_door"];

const FavoriteACourse = ({ course, favoriteCallback, showShare = false }) => {
  const [isFavorite, setIsFavorite] = useState(course?.favorite_status === "active");
  const [isFetching, setIsFetching] = useState(false);
  const [show, setShow] = useState(false);

  const event = course?.schedule;
  const showFavorite = !hideFavorites?.includes(event?.type);

  const handleFollowClick = (e) => {
    e?.stopPropagation();
    if (isFetching) return;
    setIsFetching(true);
    authAxios({
      method: "get",
      url: `favorites/set/${course.id}`,
    })
      .then((response) => {
        setIsFavorite(response?.data?.data?.favorite?.status === "active" ? true : false);

        if (favoriteCallback && course.id && response?.data?.data?.favorite?.status === "inactive")
          favoriteCallback(course.id);
      })
      .finally(() => setIsFetching(false));
  };

  const handleShareLink = () => {
    const host = window.location.host;
    const event = course?.event || course?.schedule || course;
    const eventType = event?.type;
    let link = `${host}${ROUTES.COURSE}/${course?.id}`;

    if (eventType === "live")
      link = `${host}${ROUTES.COURSE}/${course.id}${ROUTES.LIVE}/${event?.id}`;
    else if (eventType === "open_door")
      link = `${host}${ROUTES.COURSE}/${course.id}${ROUTES.OPEN_DOOR}/${event?.id}`;

    const data = {
      type: "course-card",
      app_id: course?.id,
      app_type: eventType,
    };
    authAxios.post(`/shared/`, data);

    navigator.clipboard.writeText(link);
  };

  const handleModalClick = () => {
    setShow(true);
  };

  const FavoriteMenu = () => {
    return (
      <OptionMenu label="favorite-menu" ariaLabel="favorite menu button">
        {showFavorite && (
          <div id={`favorite-course-${course?.id || null}`}>
            <Row
              $align="center"
              cursor="pointer"
              $my={2}
              onClick={handleFollowClick}
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleFollowClick();
                }
              }}
            >
              <Paragraph2 cursor mx={2}>
                Save to favorites
              </Paragraph2>
              <IconButton
                name={isFavorite ? "bookmark" : "bookmark-outline"}
                height="24px"
                width="24px"
                fill="primary"
                selected={isFavorite}
                id={`favorite-course-icon-${course?.id}`}
              />
            </Row>
          </div>
        )}
        {showFavorite && (
          <div id={`add-playlist-${course?.id}`}>
            <Row
              $align="center"
              cursor="pointer"
              $my={2}
              onClick={handleModalClick}
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleModalClick();
                }
              }}
            >
              <Paragraph2 cursor mx={2}>
                Save to playlist
              </Paragraph2>
              <IconButton name="journal" height="24px" width="24px" fill="primary" />
            </Row>
          </div>
        )}
        {showShare && (
          <div id={`share-link-${course?.id}`}>
            <OverlayTrigger
              overlay={<Tooltip id={`tooltip-share-${course?.id}`}>Click to copy</Tooltip>}
            >
              <Row
                $align="center"
                cursor="pointer"
                $my={2}
                onClick={handleShareLink}
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleShareLink();
                  }
                }}
              >
                <Paragraph2 cursor mx={2}>
                  Share
                </Paragraph2>
                <IconButton name="share-link" height="24px" width="24px" fill="primary" />
              </Row>
            </OverlayTrigger>
          </div>
        )}
      </OptionMenu>
    );
  };

  const handlePropagation = (e) => {
    e?.stopPropagation();
  };

  if (!showFavorite && !showShare) return null;

  return (
    <>
      <SavePlaylistModal show={show} setShow={setShow} course={course} />
      <div
        id={`option-menu-${course?.id || null}-${uniqueId(course?.id || null)}${
          event?.id ? "-" + event.id : ""
        }`}
      >
        <CircleButton onClick={handlePropagation}>
          <FavoriteMenu />
        </CircleButton>
      </div>
    </>
  );
};

const IconButton = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;

const CircleButton = styled.div`
  margin: 0 0.5rem;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  padding: 7px;
  cursor: pointer;
`;

export default FavoriteACourse;
