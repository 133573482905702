import { combineReducers } from "redux";

import auth from "./auth";
import notifications from "./notifications";
import search from "./search";
import ui from "./ui";
import user from "./user";

export default combineReducers({
  auth,
  notifications,
  search,
  ui,
  user,
});
