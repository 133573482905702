import ReactGA from "react-ga";

const apiKeyForHost = {
  "well.burnalong.com": "UA-78523875-7",
  "bwell.burnalong.com": "UA-78523875-8",
  "localhost:3000": "UA-000000-01",
};
// const isLocalhost = window.location.hostname === "localhost";
const config = {
  // testMode: isLocalhost,
  // debug: isLocalhost,
};

export const initializeGA = () => {
  ReactGA.initialize(apiKeyForHost[window.location.host], config);
};

export const trackPage = (page) => {
  ReactGA.pageview(page);
};
