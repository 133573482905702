import React from "react";
import { Row, Icon, Paragraph2 } from "../..";
import { level1Green, level2Purple, level3Pink } from "../../../_theme/colors";

const LevelRating = ({ data, justify = "space-between", hideCursor = false }) => {
  const courseOrProgramLevel = data?.level || data?.difficulty_level;
  const levelColor = (level) => {
    switch (level) {
      case 1:
        return level1Green;
      case 2:
        return level1Green;
      case 3:
        return level2Purple;
      case 4:
        return level2Purple;
      case 5:
        return level3Pink;
      case 6:
        return level3Pink;
      default:
        return level1Green;
    }
  };

  return (
    <Row $justify={justify} $align="center">
      {courseOrProgramLevel && (
        <Paragraph2
          $cursor={hideCursor ? "default" : "pointer"}
          weight="700"
          color={levelColor(courseOrProgramLevel)}
          $mr={4}
        >{`Level ${courseOrProgramLevel}`}</Paragraph2>
      )}
      {data?.type !== "live" && data?.rating_stars > 0 && (
        <Row $justify="center" $align="center">
          <Paragraph2 $cursor={hideCursor ? "default" : "pointer"} $mr={2} $pt={0.5}>
            {data?.rating_stars}
          </Paragraph2>
          <Icon name={"star"} fill={"orangeStar"} width={16} height={16} />
        </Row>
      )}
    </Row>
  );
};

export default LevelRating;
