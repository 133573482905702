import { ROUTES } from "../constants";
import ErrorBoundary from "../ErrorBoundary";

// Non Auth
const ForgotPassword = () => import("../../Auth/ForgotPassword");
const Login = () => import("../../Auth/Login");
const CorporateLogin = () => import("../../Auth/CorporateLogin");
const Register = () => import("../../Auth/Register");
const ResetPassword = () => import("../../Auth/ResetPassword");
const SingleSignOn = () => import("../../Auth/SingleSignOn");
const CustomLanding = () => import("../../Auth/CustomLanding");

const nonAuthPaths = [
  {
    path: `${ROUTES.LOGIN}${ROUTES.CODE}/:code`,
    component: CorporateLogin,
  },
  {
    path: ROUTES.LOGIN,
    component: Login,
  },
  {
    path: `${ROUTES.LANDING}/:code`,
    component: CustomLanding,
  },
  {
    path: ROUTES.REGISTER_CORPORATE,
    component: Register,
  },
  {
    path: `${ROUTES.REGISTER}${ROUTES.SUBUSER}/:subUserCode`,
    component: Register,
  },
  {
    path: `${ROUTES.SSO_LOGIN}`,
    component: SingleSignOn,
  },
  {
    path: `${ROUTES.REGISTER}${ROUTES.CODE}/:code`,
    component: Register,
  },
  {
    path: `${ROUTES.REGISTER}${ROUTES.CODE}`,
    component: Register,
  },
  {
    path: `${ROUTES.REGISTER}${ROUTES.CUSTOM}/:custom`,
    component: Register,
  },
  {
    path: ROUTES.REGISTER,
    component: Register,
  },

  {
    path: `${ROUTES.RESET_PASSWORD}/:code`,
    component: ResetPassword,
  },
  {
    path: `${ROUTES.PASSWORD_RESET}/:code`,
    component: ResetPassword,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    component: ForgotPassword,
  },
  {
    path: ROUTES.PASSWORD,
    component: ForgotPassword,
  },
];

const reactRouterMapping = nonAuthPaths.map(({ component, path }) => ({
  path,
  errorElement: <ErrorBoundary />,
  async lazy() {
    const Component = await component();
    return { Component: Component.default };
  },
}));

export default reactRouterMapping;
