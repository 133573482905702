import { createContext, useState, useEffect, useCallback, useMemo, memo } from "react";
import { authAxios } from "../_utils";
import moment from "moment-timezone";

// source will be one of these ["apple", "fit", "user", ""]
// where "user" is manual add only, "" is when nothing provide - not sync/manual add, apple/fit are - synced sources
// auth_required is boolean, and if true with source === "" will have no data
// auth_required with fit/apple will mean they need to reauthorize, but may have data and should show a graph

export const WearableContext = createContext();

const WearableContextProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const addSteps = useCallback((data, callback, errorCallback) => {
    authAxios
      .post(`wearables/steps/put`, data)
      .then(() => {
        getWearablesData();
        callback && callback();
      })
      .catch((err) => errorCallback(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Allowing a parametere of days allows for future builds out
  // it is not currently used, will need to update parameters for addSteps if this will chg
  const getWearablesData = useCallback((days = 365) => {
    setLoading(true);
    const userTimezone = moment.tz.guess();
    authAxios
      .get(`wearables/steps?days=${days}&tz=${userTimezone}`)
      .then((response) => {
        const steps = configureSteps(response.data?.data, days);
        const dataToSet = {
          url: response.data?.auth_url,
          steps,
          authReq: response.data?.auth_required,
          source: response.data?.source,
          days,
          totalSteps: {
            1: steps?.slice(-1)?.reduce((a, b) => a + b["count"], 0),
            7: steps?.slice(-7)?.reduce((a, b) => a + b["count"], 0),
            30: steps?.slice(-30)?.reduce((a, b) => a + b["count"], 0),
            365: steps?.slice(-365)?.reduce((a, b) => a + b["count"], 0),
          },
        };
        setData(dataToSet);
      })
      .catch((error) => console.error("error", error))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getWearablesData();
  }, [getWearablesData]);

  const contextValue = useMemo(
    () => ({
      data,
      loading,
      addSteps,
      fetch: getWearablesData,
    }),
    [data, addSteps, loading, getWearablesData]
  );

  return <WearableContext.Provider value={contextValue}>{children}</WearableContext.Provider>;
};

export const configureSteps = (data, days = 365) => {
  let day = moment();
  let stepsArray = [...Array(days + 1)].map((_, index) => {
    day = moment().subtract(index, "days");
    return {
      date: day.format("YYYY-MM-DD"),
      count: 0,
    };
  });
  let modifiedSteps = stepsArray;
  data.map((item) => {
    let indexOfStep = stepsArray.findIndex((i) => i.date === item.date);
    return (modifiedSteps[indexOfStep].count = item.count);
  });
  return modifiedSteps.reverse();
};

export default memo(WearableContextProvider);
