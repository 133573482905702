import React, { Fragment, memo } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function Breadcrumbs({ crumbs, capitalize, capLength, className = "" }) {
  return crumbs && crumbs.length ? (
    <Breadcrumb className={`pt-4 px-3 ${className}`}>
      {crumbs.map(function (crumb) {
        return (
          <Fragment key={crumb.title}>
            {crumb.link ? (
              <Breadcrumb.Item
                href={crumb.link}
                className={`${capLength ? "ellipsis line-1" : ""} ${
                  capitalize ? "text-capitalize" : ""
                }`}
                style={capLength && { maxWidth: "168px", lineHeight: 1 }}
              >
                {crumb.title}
              </Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item
                active
                className={`${capLength ? "flex-1 ellipsis line-1" : ""} ${
                  capitalize ? "text-capitalize" : ""
                }`}
                style={capLength && { lineHeight: 1 }}
              >
                {crumb.title}
              </Breadcrumb.Item>
            )}
          </Fragment>
        );
      })}
    </Breadcrumb>
  ) : null;
}

export default memo(Breadcrumbs);
