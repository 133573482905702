import renderAuthorizedRoute from "../AuthorizedRoute";

import { ROUTES } from "../constants";
import ErrorBoundary from "../ErrorBoundary";

// Dashboard
const Dashboard = () => import("../../PSS/Dashboard");

// Coursees
const Courses = () => import("../../PSS/Courses");
const AddCourseForm = () => import("../../PSS/Courses/AddCourseForm");
const CourseDetail = () => import("../../PSS/Courses/CourseDetail");

//Reviews
const Review = () => import("../../PSS/Reviews/Review");
const Reviews = () => import("../../PSS/Reviews");

//Instructor and Owner
const Instructors = () => import("../../PSS/Instructors");
const AddInstructorForm = () => import("../../PSS/Instructors/AddInstructorForm");
const InviteInstructor = () => import("../../PSS/Instructors/InviteInstructor");
const InstructorDetail = () => import("../../PSS/Instructors/InstructorDetail");
const Owners = () => import("../../PSS/Owners");
const AddOwnerForm = () => import("../../PSS/Owners/AddOwnerForm");
const OwnerDetail = () => import("../../PSS/Owners/OwnerDetail");
const InviteOwner = () => import("../../PSS/Owners/InviteOwner");

// Locations
const Locations = () => import("../../PSS/Locations");
const LocationDetail = () => import("../../PSS/Locations/LocationDetail");
const AddLocationForm = () => import("../../PSS/Locations/AddLocationForm");

// Partners
const Partners = () => import("../../PSS/Partners");
const AddPartnerForm = () => import("../../PSS/Partners/AddPartnerForm");
const PartnerDetail = () => import("../../PSS/Partners/PartnerDetail");

// Profiles
const Profile = () => import("../../PSS/Profile");
const EditProfile = () => import("../../PSS/Profile/EditProfile");
const BusinessProfile = () => import("../../PSS/Profile/BusinessProfile");

// Programs
const Programs = () => import("../../PSS/Programs");
const ProgramOverview = () => import("../../PSS/Programs/ProgramOverview");
const ProgramEditDetails = () => import("../../PSS/Programs/ProgramEditDetails");
const ProgramEditCurriculum = () => import("../../PSS/Programs/ProgramEditCurriculum");
const ProgramCurriculumListing = () => import("../../PSS/Programs/ProgramCurriculumListing");

const PageNotFoundPSS = () =>
  import("../PageNotFound").then((module) => ({
    default: module.PageNotFoundPSS,
  }));

const authPssPaths = [
  {
    path: ROUTES.PSS,
    component: Dashboard,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.COURSES}${ROUTES.NEW}`,
    component: AddCourseForm,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.COURSE}/:id${ROUTES.EDIT}`,
    component: AddCourseForm,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.COURSE}/:id`,
    component: CourseDetail,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.COURSES}`,
    component: Courses,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.REVIEWS}`,
    component: Reviews,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.REVIEW}/:id`,
    component: Review,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.INSTRUCTORS}`,
    component: Instructors,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.INSTRUCTORS}${ROUTES.INVITE}`,
    component: InviteInstructor,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.INSTRUCTORS}/:id${ROUTES.EDIT}`,
    component: AddInstructorForm,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.INSTRUCTORS}/:id`,
    component: InstructorDetail,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.LOCATIONS}`,
    component: Locations,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.LOCATIONS}${ROUTES.NEW}`,
    component: AddLocationForm,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.LOCATIONS}/:id${ROUTES.EDIT}`,
    component: AddLocationForm,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.LOCATIONS}/:id`,
    component: LocationDetail,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PARTNERS}`,
    component: Partners,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PARTNERS}/:id${ROUTES.EDIT}`,
    component: AddPartnerForm,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PARTNERS}/:id`,
    component: PartnerDetail,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PROFILE}`,
    component: Profile,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PROFILE}${ROUTES.EDIT}`,
    component: EditProfile,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PROFILE}${ROUTES.BUSINESS}`,
    component: BusinessProfile,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PROGRAMS}`,
    component: Programs,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PROGRAM}${ROUTES.CREATE}`,
    component: ProgramEditDetails,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PROGRAM}${ROUTES.CREATE}/:id${ROUTES.EDIT}`,
    component: ProgramEditDetails,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PROGRAM}${ROUTES.COURSES}/:id`,
    component: ProgramCurriculumListing,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PROGRAM}${ROUTES.CREATE}/:id${ROUTES.COURSE}/:order`,
    component: ProgramEditCurriculum,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PROGRAM}/:id`,
    component: ProgramOverview,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.OWNERS}`,
    component: Owners,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.OWNERS}${ROUTES.INVITE}`,
    component: InviteOwner,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.OWNERS}/:id`,
    component: OwnerDetail,
    noShell: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.OWNERS}/:id${ROUTES.EDIT}`,
    component: AddOwnerForm,
    noShell: false,
  },
  {
    path: ROUTES.PSS,
    component: PageNotFoundPSS,
    noShell: false,
  },
];

const reactRouterMapping = authPssPaths.map(({ component, noShell, path }) => ({
  path,
  errorElement: <ErrorBoundary />,
  async lazy() {
    const loadedComponent = await component();
    return {
      Component: renderAuthorizedRoute({
        children: <loadedComponent.default />,
        noShell,
        type: "pss",
      }),
    };
  },
}));

export const authPssPathsNoProviders = authPssPaths.map(({ component, path }) => ({
  path,
  async lazy() {
    const Component = await component();
    return { Component: Component.default };
  },
}));

export default reactRouterMapping;
