import { useEffect } from "react";
import useTimer from "../_hooks/useTimer";
import useIntervalCall from "./useIntervalCall";
import { pingActiveUser, getNotificationSchedule } from "./actions";
import { useDispatch, useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";

const useWatchdog = () => {
  const { start, elapsed, stop } = useTimer(0, 10);
  //const token = useSelector((state) => state.auth.token);
  const token = secureLocalStorage.getItem("token");
  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) {
      stop();
    } else {
      start();
    }
  }, [token, start, stop]);

  const { dismissed } = useSelector((state) => state.notifications.schedule);
  const userId = useSelector((state) => state?.user?.data?.user.id);

  const ignorePing = !token;

  useEffect(() => {
    start();
  }, [elapsed, start]);

  // ping user is active
  useIntervalCall(elapsed, 300, pingActiveUser, ignorePing);

  useIntervalCall(
    elapsed,
    30,
    () => getNotificationSchedule(dispatch, userId, dismissed, 15),
    ignorePing
  );
};

export default useWatchdog;
