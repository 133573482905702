import { memo } from "react";
import styled from "styled-components";
import { Col, Paragraph2, Paragraph3, Row } from "../..";

const InstructorCardRow = ({ data, program }) => {
  const instructorName = data?.instructor?.first_name
    ? `${data?.instructor?.first_name} ${data?.instructor?.last_name}`
    : "None";
  const language = data?.languageoptions?.title;

  return program ? (
    <Paragraph2 weight="700" $mt={1}>
      by {instructorName}
    </Paragraph2>
  ) : (
    <InstructorContent>
      <InstructorAndLanguageRow>
        <Paragraph3 weight="500" color="grey3">
          Instructor
        </Paragraph3>
        {language !== "English" && <Paragraph3>{language}</Paragraph3>}
      </InstructorAndLanguageRow>
      <Paragraph2 weight="700" $mt={1}>
        {instructorName}
      </Paragraph2>
    </InstructorContent>
  );
};

const InstructorAndLanguageRow = styled(Row)`
  justify-content: space-between;
`;

const InstructorContent = styled(Col)`
  margin-top: auto;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  justify-content: flex-end;
`;

export default memo(InstructorCardRow);
