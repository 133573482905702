import React, { memo } from "react";
import styled from "styled-components";
import { Controller } from "react-hook-form";
import Form from "react-bootstrap/Form";

import FormGroup from "./FormGroup";

const FormInput = ({
  append,
  control,
  defaultValue = "",
  error,
  errors,
  helperText,
  label,
  name,
  prepend,
  required,
  rules,
  ...rest
}) => {
  return (
    <FormGroup
      append={append}
      error={error}
      errors={errors}
      helperText={helperText}
      label={label}
      name={name}
      prepend={prepend}
      required={required || rules?.required}
      {...rest}
    >
      {control ? (
        <Controller
          as={<InputWrapper $pre={prepend} $append={append} $error={error || errors} {...rest} />}
          control={control}
          defaultValue={defaultValue}
          name={name}
          rules={rules}
          {...rest}
        />
      ) : (
        <InputWrapper
          autoComplete={"" + Math.random()}
          $append={append}
          $pre={prepend}
          defaultValue={defaultValue}
          $error={error || errors}
          name={name}
          {...rest}
        />
      )}
    </FormGroup>
  );
};

export const InputWrapper = styled(Form.Control)`
  min-height: calc(2.2rem + 2px) !important;
  height: ${({ $inputHeight }) => $inputHeight};
  background-color: ${(props) =>
    props?.disabled ? "#DEDEDE" : props.theme.palette.neutralGrey} !important;
  border-width: 0.25px;
  border-color: ${(props) =>
    props.$error ? props.theme.palette.red001 : props.theme.palette.grey002};
  border-radius: 8px !important;
  color: ${(props) =>
    props?.disabled ? props.theme.palette.grey002 : props.theme.palette.primaryBlue} !important;
  font-family: "DM Sans", sans-serif;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    transition-delay: background-color 5000s, color 5000s;
    -webkit-text-fill-color: #002448 !important;
  }

  &:active,
  &:focus {
    border-color: ${(props) =>
      props.$error ? props.theme.palette.red001 : props.theme.palette.primaryBlue} !important;
    border-width: 2px;
    box-shadow: none;
    background-color: ${(props) => props.theme.palette.neutralGrey};
  }
`;

export default memo(FormInput);
