import { EVENTS_ALERT, EVENTS_DISMISS } from "../../reducers/notifications/event";

export const alertEvent = (header, subHeader, body, url) => {
  return {
    type: EVENTS_ALERT,
    header,
    subHeader,
    body,
    url,
  };
};

export const dismissEvent = () => ({
  type: EVENTS_DISMISS,
  action: {},
});
