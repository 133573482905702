import { memo, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";

import { Row } from "../../Layout";
import { Button } from "../../Buttons/Button";

import authAxios from "../../../_utils/authAxios";
import { UserContext } from "../../../_context/UserContext";

import { ROUTES } from "../../../_routes";

const AcceptDecline = ({ challenge, pending, onDecline, linkState, isDraft }) => {
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);
  const { id: userID } = userData;
  const [isLoading, setIsLoading] = useState(false);
  const isTC = Boolean(challenge?.teamchallenge);
  const handleDecline = (e) => {
    e.stopPropagation();
    setIsLoading(true);
    const url = isTC
      ? `teamchallenges/user/update/${challenge?.id}/declined`
      : `challengeusers/update`;
    let data;
    data = !isTC && {
      status: "inactive",
      user: userID,
      challenge: challenge.challenge.id,
    };
    authAxios
      .post(url, data)
      .then(() => onDecline && onDecline())
      .finally(() => setIsLoading(false));
  };

  const handleAccept = (e) => {
    e.stopPropagation();
    setIsLoading(true);

    const url = isTC
      ? `teamchallenges/user/update/${challenge?.id}/active`
      : `challengeusers/update`;
    let data;
    if (!isTC) {
      const todaysDate = moment().startOf("day");
      let startDate = moment(todaysDate).isBefore(challenge?.date_start)
        ? challenge.date_start
        : todaysDate.format();
      data = {
        status: "active",
        date_start: startDate,
        user: userID,
        challenge: challenge?.challenge?.id,
        habit_id: challenge?.challenge?.habit?.id,
      };
    }
    const detailsLink = isTC
      ? `${ROUTES.TEAM_CHALLENGES}/${challenge?.teamchallenge.id}`
      : `${ROUTES.CHALLENGE}/${challenge.challenge.id}`;
    isTC
      ? authAxios.post(url).then(() => navigate(detailsLink, { state: linkState }))
      : authAxios.post(url, data).then(() => navigate(detailsLink, { state: linkState }));
    setIsLoading(false);
  };
  return (isTC && pending && !isDraft) || (challenge?.status === "pending" && !isDraft) ? (
    <Wrapper>
      <ButtonWrapper
        disabled={isLoading}
        bType="outline"
        dark="false"
        onClick={handleDecline}
        $mr={1}
      >
        Decline
      </ButtonWrapper>
      <ButtonWrapper disabled={isLoading} onClick={handleAccept} $ml={1}>
        Accept
      </ButtonWrapper>
    </Wrapper>
  ) : null;
};

const Wrapper = styled(Row)`
  margin-top: 1.5rem;
  justify-content: space-between;

  @media screen and (max-width: 1265px) {
    justify-content: center;
  }
`;

const ButtonWrapper = styled(Button)`
  margin-left: ${({ $ml }) => $ml && "0.25rem"};
  margin-right: ${({ $mr }) => $mr && "0.25rem"};
  @media screen and (max-width: 1265px) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;

    &:last-child {
      margin-top: 0.5rem;
    }
  }
`;

export default memo(AcceptDecline);
