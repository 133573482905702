export const ROUTES = {
  ABOUT: "/about",
  ACCESS_DENIED: "/access-denied",
  ADD_CONTACTS: "/add-contacts",
  ALL: "/all",
  BUSINESS: "/business",
  CALENDAR: "/calendar",
  CATEGORIES: "/categories",
  CATEGORY: "/category",
  CHALLENGE: "/challenge",
  CHALLENGES: "/challenges",
  CHAMPION: "/champion",
  CODE: "/code",
  COMMUNITIES: "/communities",
  COMMUNITY: "/community",
  CONDITIONS: "/conditions",
  CONFIRMATION: "/confirmation",
  CONTACTS: "/contacts",
  COURSE: "/class",
  COURSE_FINDER: "/class-finder",
  COURSES: "/classes",
  CUSTOM: "/custom",
  CREATE: "/create",
  CREATEROOM: "/create-room",
  DASHBOARD: "/",
  DESIGN: "/design",
  DETAIL: "/detail",
  EDIT: "/edit",
  ELIGIBILITY: "/eligibilty",
  ENROLL: "/enroll",
  EVENT: "/event",
  EVENTS: "/events",
  FAVORITES: "/favorites",
  FITNESS: "/fitness",
  FOLLOWING: "/following",
  FORGOT_PASSWORD: "/forgot-password",
  GOALS: "/goals",
  GROUP_COURSE: "/group-class",
  HEALTH_METRICS: "/health-metrics",
  HISTORY: "/history",
  HOMEWORK: "/homework",
  INSTRUCTOR: "/instructor",
  INSTRUCTORS: "/instructors",
  INTERESTS: "/interests",
  INVITE: "/invite",
  LANDING: "/landing",
  LEAVE_REVIEW: "/leave-review",
  POST_ASSESSMENT: "/post-assessment",
  LIVE: "/live",
  LIVE_OPEN_DOOR: "/live-open-door",
  LIVESTREAM: "/livestream",
  LIVE_COURSE: "/class/live",
  LOCATION: "/location",
  LOCATIONS: "/locations",
  LOGIN: "/login",
  MEMBERS: "/members",
  MEMBERSHIP: "/membership",
  MY_CONTACTS: "/my-contacts",
  NEW: "/new",
  NOTIFICATIONS: "/alerts",
  ONBOARDING: "/onboarding",
  OPEN_DOOR: "/open",
  OWNER: "/owner",
  OWNERS: "/owners",
  PARTNER: "/partner",
  PARTNERS: "/partners",
  PARTNERSHIPS: "/partnerships",
  PASSWORD: "/password",
  PASSWORD_RESET: "/password/reset",
  PAST: "/past",
  PAYMENT_OPTIONS: "/payment-options",
  PENDING: "/pending",
  PLAYLIST: "/playlist",
  PLN: "/plns",
  POPULAR: "/popular",
  PRIVATE: "/private",
  PROFILE: "/profile",
  PROGRAM: "/program",
  PROGRAMS: "/programs",
  PROGRESS: "/progress",
  ACHIEVEMENTS: "/progress/achievements",
  ACHIEVEMENTS_ALL: "/progress/achievements/all",
  ACHIEVEMENTS_DAY: "/progress/achievements/day-streak",
  ACHIEVEMENTS_WEEK: "/progress/achievements/week-streak",
  ACHIEVEMENTS_MONTH: "/progress/achievements/month-streak",
  POINTS_HISTORY: "/progress/points",
  PSS: "/pss",
  PUBLIC: "/public",
  RECOMMENDATIONS: "/recommendations",
  RECORDINGS: "/recordings",
  REGISTER: "/register",
  REGISTER_CORPORATE: "/register/partner",
  REGISTER_DIRECT_CUSTOMER: "/register/direct",
  RESET_PASSWORD: "/reset-password",
  REVIEW: "/review",
  REVIEWS: "/reviews",
  SCHEDULE: "/schedule",
  SEARCH: "/search",
  RESULTS: "/results",
  SETTINGS: "/settings",
  SSO_LOGIN: "/sso-login",
  SUBSCRIPTIONS_NEW: "/subscriptions-new",
  SUBSCRIPTIONS_UPGRADE: "/subscriptions-upgrade",
  SUBSCRIPTIONS_DOWNGRADE: "/subscriptions-downgrade",
  SUBUSER: "/subuser",
  SUB_ACCOUNTS: "/sub-accounts",
  TEAM_CHALLENGES: "/team-challenges",
  TEAMS: "/teams",
  VIDEO: "/video",
  WEBINAR: "/webinar",
  WELCOME: "/welcome",
  WELLNESS_GOALS: "/wellness-goals",
  WELLNESS_HABITS: "/wellness-habits",
  WELLNESS_LEVEL: "/wellness-level",
  ZONE: "/zone",
  ZONES: "/zones",
};

export default ROUTES;
