export const REGISTER_REQUEST = "auth/REGISTER_REQUEST";
export const REGISTER_SUCCESS = "auth/REGISTER_SUCCESS";
export const REGISTER_FAILURE = "auth/REGISTER_FAILURE";

const initialState = {
  isFetching: false,
  payload: null,
  status: null,
  errors: null,
};

export const register = (state = initialState, action) => {
  const { response, errors } = action;

  switch (action.type) {
    case REGISTER_REQUEST:
      return {
        isFetching: true,
        payload: null,
        status: null,
        errors: null,
      };
    case REGISTER_SUCCESS:
      return {
        isFetching: false,
        payload: response?.payload,
        status: response?.status,
        errors: null,
      };
    case REGISTER_FAILURE:
      return {
        isFetching: false,
        payload: null,
        status: response?.status,
        errors,
      };
    default:
      return state;
  }
};

export default register;
