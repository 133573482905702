import { memo } from "react";
import Skeleton from "react-loading-skeleton";
import { BadgeText } from "..";

const StringifyItems = ({ items, loading, bg = "grey2", noItemsReturnValue = null }) => {
  if (!items?.length) return noItemsReturnValue;
  return items.map((item) => {
    return loading ? (
      <Skeleton width="100%" height="2rem" />
    ) : (
      <BadgeText
        key={item?.id || item}
        bg={bg}
        textColor="white"
        title={item?.title || item}
        $mr={2}
      />
    );
  });
};

export default memo(StringifyItems);
