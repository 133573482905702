export const ICONSUPDATED = {
  ABS: "abs",
  ACCESSIBILITY: "accessibility",
  ADAPTIVE: "adaptive",
  ARMS: "arms",
  ARTHRITIS: "arthritis",
  BARRE: "barre",
  BARS: "bars",
  BASKETBALL: "basketball",
  BELL: "bell",
  BELL_OUTLINE: "bell-outline",
  BODYWEIGHT: "bodyweight",
  BOOKMARK: "bookmark",
  BOOKMARK_OUTLINE: "bookmark-outline",
  BOOTCAMP: "bootcamp",
  BOXING: "boxing",
  BULLSEYE: "bullseye",
  CALENDAR: "calendar",
  CANCER: "cancer",
  CARDIO: "cardio",
  CARDIONEW: "cardio-new",
  CHECK: "check",
  CHECK_CIRCLE: "check-circle",
  CHEVRON_DOWN: "chevron-down",
  CHEVRON_LEFT: "chevron-left",
  CHEVRON_LEFT_CIRCLE: "chevron-left-circle",
  CHEVRON_RIGHT: "chevron-right",
  CHEVRON_UP: "chevron-up",
  CLASSES: "classes",
  CLAP: "clap",
  CLAP_OUTLINE: "clap-outline",
  CLOCK: "clock",
  COG: "cog",
  COMMENT: "comment",
  COMMENT_OUTLINE: "comment-outline",
  COOKING_POT: "cooking-pot",
  CYCLE: "cycle",
  DANCE: "dance",
  DIABETES: "diabetes",
  DOG_WALKING: "dog",
  EDIT: "edit",
  EDUCATIONAL: "educational",
  ELLIPSIS_V: "ellipsis-v",
  ELLIPSIS_H: "ellipsis-h",
  ELLIPTICAL: "elliptical",
  EXIT: "exit",
  EYE: "eye",
  FACEBOOK_CIRCLE: "facebook-circle",
  FAMILY_TIME: "family-time",
  FINANCIAL: "financial",
  FISH: "fish",
  FIT_OVER: "fit-over",
  FLAG: "flag",
  FLOSS: "floss",
  GLUTES: "glutes",
  GOLF: "golf",
  GRATEFUL: "grateful",
  GROUP: "group",
  HEART: "heart",
  HEART_OUTLINE: "heart-outline",
  HEARTBEAT: "heartbeat",
  HELLO: "hello",
  HIIT: "hiit",
  HIKING: "hiking",
  HOME: "home",
  ICE_SKATING: "ice-skating",
  INDOOR_WORKOUT: "indoor-workout",
  INFO_CIRCLE: "info-circle",
  INSTRUCTORS: "instructors",
  INTRO_VIDEOS: "intro-videos",
  JOURNAL: "journal",
  KAYAKING: "kayaking",
  KID_TIME: "kid-time",
  LEARN: "learn",
  LIFE_COACHING: "life-coaching",
  LIGHTING: "lighting",
  LINKEDIN_CIRCLE: "linkedin-circle",
  LIVE: "live",
  LOCATIONS: "locations",
  LOCATIONS_NEW: "locations-new",
  LOGO: "logo",
  LOGO_FULL: "logo-full",
  LUNCH: "lunch",
  MEDITATION: "meditation",
  MENTAL_HEALTH: "mental-health",
  MICROPHONE: "microphone",
  MICROPHONE_SLASH: "microphone-slash",
  MINDFULNESS: "mindfulness",
  MINDFULL_BREATHING: "mindfull-breathing",
  MOUNTAIN: "mountain",
  MS: "ms",
  NO_DESSERT: "no-dessert",
  NO_FAST_FOOD: "no-fast-food",
  NO_MEAT: "no-meat",
  NO_SCREEN_TIME: "no-screen-time",
  NO_SODA: "no-soda",
  NUTRITION: "nutrition",
  OFFICE_WORKOUT: "office",
  OPEN_DOOR: "open-door",
  OUTSIDE: "outside",
  OWNERS: "owners",
  PARENTING: "parenting",
  PARTNERS: "partners",
  PARKINSONS: "parkinsons",
  PILATES: "pilates",
  PHYSICAL_THERAPY: "pt",
  PLUS_CIRCLE: "plus-circle",
  PLUS_CIRCLE_OUTLINE: "plus-circle-outline",
  PRENATAL: "prenatal",
  PROFILE: "profile",
  PROFILE_BUSINESS: "profile-business",
  PROGRAM: "program",
  PSS: "pss",
  QUESTION: "question",
  READ: "read",
  REVIEWS: "reviews",
  ROWING: "rowing",
  RUNNING: "running",
  SALAD: "salad",
  SCALE: "scale",
  SEARCH: "search",
  SELF_DEFENSE: "self-defense",
  SENIORS: "seniors",
  SHARE: "share",
  SHARE_LINK: "share-link",
  SKIING: "skiing",
  SLEEP: "sleep",
  SOCIAL: "social",
  SOCCER: "soccer",
  SOLO: "solo",
  SPORTS: "sports",
  SPORTS_PERFORMANCE: "sports-performance",
  STAIRS: "stairs",
  STAIR_STEPPER: "stair-stepper",
  STAND_UP_PADDLE: "stand-up-paddle",
  STAR: "star",
  STAR_HALF: "star-half",
  STAR_OUTLINE: "star-outline",
  STARTERS: "starters",
  STEPS: "steps",
  STRENGTH: "strength",
  STRESS: "stress",
  STRETCH: "stretch",
  SHOPPING_CART: "shopping-cart",
  SUMMER_CAMP: "summer-camp",
  SURFING: "surfing",
  SWIM: "swim",
  TAI_CHI: "tai-chi",
  TAKE_FIVE: "take-5",
  TENNIS: "tennis",
  TIMES: "times",
  TIMES_CIRCLE: "times-circle",
  TIMES_CIRCLE_OUTLINE: "times-circle-outline",
  TRAVEL: "travel",
  TREADMILL: "treadmill",
  TWITTER_CIRCLE: "twitter-circle",
  USER: "user",
  USER_OUTLINE: "user-outline",
  USER_SOCIAL: "user-social",
  VEGETABLE: "vegetable",
  VIDEO: "video",
  VIDEO_SLASH: "video-slash",
  VIRTUAL_INDOOR_RIDE: "virtual-indoor-ride",
  VIRTUAL_INDOOR_RUN: "virtual-indoor-run",
  VOLLEYBALL: "volleyball",
  VOLUME_MUTE: "volume-mute",
  VOLUME: "volume",
  VOLUNTEER: "volunteer",
  WATER: "water",
  WALKING: "walking",
  WARNING: "warning",
  WELLNESS_VIEW: "wellness-view",
  WEIGHT_TRAINING: "weight-training",
  WORKOUT: "workout",
  YARD_WORK: "yard-work",
  YOGA: "yoga",
  YOGA_NEW: "yoga-new",
  YOUTH: "youth",
  ZUMBA: "zumba",
};

export default ICONSUPDATED;
