import { memo, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { baseCard } from "../../_theme/general";
import { baColor } from "../../_utils/color";
import { Paragraph1 } from "../Typography";

const Alert = ({ width, height, children, color = "liveRed", bg = "white", show = true }) => {
  const theme = useContext(ThemeContext);
  if (!show) return null;

  return (
    <Wrapper $width={width} $height={height} $bg={baColor(bg, theme)}>
      <Paragraph1 color={baColor(color, theme)}>{children}</Paragraph1>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: ${({ $height }) => $height || "auto"};
  width: ${({ $width }) => $width || "max-content"};
  max-width: 90%;
  background-color: ${({ $bg }) => $bg}!important;
  border-radius: 8px;
  margin: 10px;
  margin-left: auto;
  margin-right: auto;
  ${baseCard}
`;

export default memo(Alert);
