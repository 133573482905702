export const USER_REQUEST = "auth/USER_REQUEST";
export const USER_SUCCESS = "auth/USER_SUCCESS";
export const USER_FAILURE = "auth/USER_FAILURE";
export const LOGOUT = "auth/LOGOUT";

const initialState = {
  isFetching: false,
  data: null,
  errors: null,
};

export const register = (state = initialState, action) => {
  const { data, errors } = action;

  switch (action.type) {
    case USER_REQUEST:
      return {
        isFetching: true,
        data: null,
        errors: null,
      };
    case USER_SUCCESS:
      return {
        isFetching: false,
        data,
        errors: null,
      };
    case USER_FAILURE:
      return {
        isFetching: false,
        data: null,
        errors,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default register;
