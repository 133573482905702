export const apiUrl = import.meta.env.VITE_APP_API_URL || "http://localhost:3001/mock-api";

export const googleClientId = import.meta.env.VITE_APP_GOOGLE_CLIENT_ID;
export const googleApiKey = import.meta.env.VITE_APP_GOOGLE_API_KEY;
export const microsoftTenantId = import.meta.env.VITE_APP_MICROSOFT_TENANT_ID;
export const microsoftClientId = import.meta.env.VITE_APP_MICROSOFT_CLIENT_ID;

export const tivityApiKey = import.meta.env.VITE_APP_TIVITY_API_KEY;
export const tivityGoogleApiKey = import.meta.env.VITE_APP_TIVITY_GOOGLE_API_KEY;
export const tivityProgram = import.meta.env.VITE_APP_TIVITY_PROGRAM;
export const tivityScriptURL = import.meta.env.VITE_APP_TIVITY_SCRIPT_URL;

export const brazeKeys = {
  API: import.meta.env.VITE_APP_BRAZE_API_KEY,
  SDK: import.meta.env.VITE_APP_BRAZE_SDK,
};

export const headers = {
  "Content-Type": "application/json",
};

export const tokenExpirationTime = Number(import.meta.env.VITE_APP_TOKEN_EXPIRATION);

const checkTrue = (flag) => Number(flag) === 1;

export const flagFeatures = {
  BRANDING: "branding",
  CHALLENGES: "challenges",
  CHAMPION: "champion",
  COMMUNITIES: "communities",
  COURSEHISTORY: "courseHistory",
  GOALS: "goals",
  HEALTHMETRICS: "healthMetrics",
  NOTIFICATIONS: "notifications",
  PROGRAMS: "programs",
  PROGRESS: "progress",
  PSS_PROGRAMS: "pssprograms",
  TEAMCHALLENGES: "teamChallenges",
  PLAYLISTS: "playlists",
  CALENDAR: "calendar",
  LIVECALENDAR: "livecalendar",
  ASSESSMENTS: "assessments",
  HOMEWORKS: "homeworks",
  NOLOYALTY: "noloyalty",
};

export const featureFlags = {
  branding: checkTrue(import.meta.env.VITE_APP_BRANDING),
  challenges: checkTrue(import.meta.env.VITE_APP_CHALLENGES),
  champion: checkTrue(import.meta.env.VITE_APP_CHAMPION),
  communities: checkTrue(import.meta.env.VITE_APP_COMMUNITIES),
  courseHistory: checkTrue(import.meta.env.VITE_APP_COURSE_HISTORY),
  goals: checkTrue(import.meta.env.VITE_APP_GOALS),
  healthMetrics: checkTrue(import.meta.env.VITE_APP_HEALTH_METRICS),
  notifications: checkTrue(import.meta.env.VITE_APP_NOTIFICATIONS),
  programs: checkTrue(import.meta.env.VITE_APP_PROGRAMS),
  progress: checkTrue(import.meta.env.VITE_APP_PROGRESS),
  teamChallenges: checkTrue(import.meta.env.VITE_APP_TEAM_CHALLENGES),
  playlists: checkTrue(import.meta.env.VITE_APP_PLAYLISTS),
  calendar: checkTrue(import.meta.env.VITE_APP_CALENDAR),
  livecalendar: checkTrue(import.meta.env.VITE_APP_LIVE_CALENDAR),
  assessments: checkTrue(import.meta.env.VITE_APP_ASSESSMENTS),
  homeworks: checkTrue(import.meta.env.VITE_APP_HOMEWORKS),
};

export const GROUP_TYPES = {
  ADMIN: Number(import.meta.env.VITE_APP_ADMIN),
  BETA: Number(import.meta.env.VITE_APP_BETA),
  INSTRUCTOR: Number(import.meta.env.VITE_APP_INSTRUCTOR),
  MANAGER: Number(import.meta.env.VITE_APP_MANAGER),
  OWNER: Number(import.meta.env.VITE_APP_OWNER),
  SUBUSER: Number(import.meta.env.VITE_APP_SUBUSER),
  USER: Number(import.meta.env.VITE_APP_USER),
  CHAMPION: Number(import.meta.env.VITE_APP_CHAMPION),
};

export const defaultWeekdays = {
  1: { label: "Mon", selected: false, value: 1 },
  2: { label: "Tue", selected: false, value: 2 },
  3: { label: "Wed", selected: false, value: 3 },
  4: { label: "Thu", selected: false, value: 4 },
  5: { label: "Fri", selected: false, value: 5 },
  6: { label: "Sat", selected: false, value: 6 },
  7: { label: "Sun", selected: false, value: 7 },
};

export const FitnessLocationDisclaimer = `*Access to Fitness locations provided by Tivity Health Services, LLC,
an affiliate of Burnalong, Inc. Fitness locations are not owned or
operated by Tivity Health or its affiliates. BurnAlong+ Subscription
with fitness location access option entitles subscriber to use the
fitness location facilities and amenities available to the holder of a
basic membership at the fitness locations. Facilities and amenities
vary by location.`;

export const RedirectToStripeDisclaimer = (buttonName = "Take the next step") =>
  `By clicking "${buttonName}" you will leave Burnalong.com to go to
Stripe.com to process your payment. Please note that Stripe.com is not
owned, operated or controlled by Burnalong, Inc. or Tivity Health,
Inc. By visiting Stripe.com you will be subject to its terms of use
and private policy.`;

export const SubscriptionRenewalDisclaimer = `Your Burnalong subscription plan will continue to renew each month, and
      your payment method will be automatically charged once a month on the date
      you signed up, until you cancel your subscription. You may cancel your
      subscription by calling (800) 216-0253 (toll free) or emailing${" "}`;
// Spacing added as in between these two disclaimers is the email address
export const SubscriptionCancelDisclaimer = `${" "}If you cancel with time left in your monthly subscription plan, you can
use your Burnalong plan until your plan cancels at the end of that billing
period.`;

export const SMS_CONSENT_NAME = "SMSPromos";

const datadogEnvironments = ["production", "remote-dev-env", "staging"];
export const isDatadogEnvironment = datadogEnvironments.includes(import.meta.env.VITE_APP_ENV);
