import { memo } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import FormGroup from "./FormGroup";

const FormTextarea = ({
  control,
  errors,
  error,
  label,
  name,
  helperText,
  required,
  rules,
  rows = 5,
  defaultValue = "",
  ...rest
}) => {
  return (
    <FormGroup
      label={label}
      name={name}
      helperText={helperText}
      required={required || rules?.required}
      errors={errors}
      error={error}
      {...rest}
    >
      {control ? (
        <Controller
          defaultValue={defaultValue}
          as={<MultiLineWrapper rows={rows} id={name} {...rest} />}
          control={control}
          name={name}
          required={required}
          rules={rules}
          {...rest}
        />
      ) : (
        <MultiLineWrapper rows={rows} id={name} {...rest} name={name} required={required} />
      )}
    </FormGroup>
  );
};

const MultiLineWrapper = styled.textarea`
  background-color: ${(props) => props.theme.palette.neutralGrey};
  border-color: ${(props) =>
    props.$error ? props.theme.palette.red001 : props.theme.palette.grey002};
  border-radius: 8px;
  padding: 6px 12px;
  color: ${(props) => props.theme.palette.primaryBlue};
  outline-color: ${(props) => props.theme.palette.primaryBlue};
`;

export default memo(FormTextarea);
