export const FINDER_REQUEST = "search/FINDER_REQUEST";
export const FINDER_SUCCESS = "search/FINDER_SUCCESS";
export const FINDER_FAILURE = "search/FINDER_FAILURE";
export const FINDER_SET_VALUES = "search/FINDER_SET_VALUES";
export const FINDER_RESET = "search/FINDER_RESET";
export const FINDER_SHOW_ON_MOBILE = "search/FINDER_SHOW_ON_MOBILE";

const initialState = {
  isFetching: false,
  errors: null,
  payload: null,
  showFinderOnMobile: false,
  values: null,
};

export const finder = (state = initialState, action) => {
  const { payload, values, errors, showFinderOnMobile } = action;

  switch (action.type) {
    case FINDER_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: null,
        payload: null,
      };
    case FINDER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errors: null,
        payload,
      };
    case FINDER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors,
      };
    case FINDER_SET_VALUES:
      localStorage.setItem("finder", JSON.stringify(values));
      return {
        ...state,
        values,
      };
    case FINDER_SHOW_ON_MOBILE:
      return {
        ...state,
        showFinderOnMobile,
      };
    case FINDER_RESET:
      localStorage.removeItem("finder");
      return initialState;
    default:
      return state;
  }
};

export default finder;
