import moment from "moment";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Heading5, Paragraph2 } from "../Typography";
import { AvatarGroup } from "..";
import CardBase from "./CardBase";
import { AcceptDecline, LevelRating, LiveButton, InstructorCardRow } from "./_components";
import { lineClamp, ellipsis } from "../../_theme/general";
import { Col, Row } from "../Layout";
import { Icon } from "..";
import DatePill from "./_components/DatePill";
import ChallengeTargetDescription from "../../Challenges/_components/ChallengeTargetDescription";

import { utcDateToDisplay } from "../../_utils";
import { useLiveClassStatus } from "./_utils.js";

import { ROUTES } from "../../_routes";
import { red001 } from "../../_theme/colors";

const HomeInviteCard = ({ item, parentPage, handleDecline, flipped, ...rest }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const event = item?.event;

  const course = item?.course;
  const canJoin = useLiveClassStatus(event?.date_schedule);

  const isTC = item?.type === "teamchallenge";
  const isIndividual = item?.type === "individual";
  const isChallenge = isIndividual || isTC;

  const srcImage = isTC ? "tc" : isIndividual ? "challenge" : course?.file_poster;
  const linkState = { from: parentPage };

  const startDate = useMemo(
    () => (isIndividual ? item?.challenge?.date_start : item?.teamchallenge?.date_start),
    [item, isIndividual]
  );

  const endDate = useMemo(
    () => (isIndividual ? item?.challenge?.date_end : item?.teamchallenge?.date_end),
    [item, isIndividual]
  );

  const handleOnClick = () => {
    const breadcrumbs = location.pathname?.split("/") || [];
    const pathname = isChallenge ? item.link : ROUTES.SCHEDULE;
    navigate(pathname, { state: { from: parentPage, breadcrumbs } });
  };

  const onDecline = () => {
    if (handleDecline) handleDecline(item.id);
  };

  return (
    <CardBase
      id={item?.id || item?.event?.id}
      image={srcImage}
      flipped={flipped}
      flippedContent={{
        body: `You have declined ${item.title}`,
        icon: "times-circle",
        fill: red001,
      }}
      overlayCentered={isChallenge ? <MountainOverlay /> : null}
      onClick={handleOnClick}
      forceRadius
      {...rest}
    >
      {!isChallenge && <LevelRating data={item.course} />}
      <TitleContent $amt={2} $cursor="pointer" weight={700} style={{ margin: 0 }}>
        {item?.title}
      </TitleContent>
      {!isChallenge && (
        <DatePill
          data={{
            show: true,
            bg: canJoin ? "liveRed" : "white",
            date: moment(
              utcDateToDisplay(
                item?.date_scheduled?.replace("T", " ")?.replace("Z", "") || event?.date_schedule
              )
            ).format("ddd, M/D • h:mm A"),
          }}
        />
      )}
      {isChallenge && (
        <>
          <IconRow icon="calendar">
            {moment(startDate).format("L")} - {moment(endDate).format("L")}
          </IconRow>
          <IconRow icon="flag">
            <ChallengeTargetDescription challenge={item} />
          </IconRow>
          <IconRow icon="user-social" hide={!item?.team?.title}>
            My Team: {item?.team?.title}
          </IconRow>
        </>
      )}
      {!isChallenge && <InstructorCardRow data={item} />}
      {event?.type === "group" && (
        <AvatarGroup
          users={event?.invitees}
          text={`${event?.invitees_count} ${
            event?.invitees_count === 1 ? "person is" : "people are"
          } invited`}
        />
      )}

      {isChallenge ? (
        <Row style={{ marginTop: "auto" }}>
          <AcceptDecline
            challenge={item}
            linkState={linkState}
            pending={item?.status === "pending"}
            onDecline={() => onDecline()}
            {...rest}
          />
        </Row>
      ) : (
        <LiveButton course={item} state={linkState} />
      )}
    </CardBase>
  );
};

const TitleContent = styled(Heading5)`
  ${lineClamp}
  ${ellipsis}
  height: 2.6em;
  margin-bottom: 1rem;
  cursor: ${({ $cursor }) => $cursor};
`;

const MountainOverlay = () => {
  return <Icon name="mountain" fill="white" width="4rem" height="4rem" />;
};

const IconRow = ({ hide, icon, children }) => {
  if (hide) return null;
  return (
    <Row $align="center" $mt={1} $mb={2}>
      <Col xs={1}>
        <Icon name={icon} width="1.5rem" height="1.5rem" />
      </Col>
      <Col>
        <Paragraph2 weight="700" $pl={2}>
          {children}
        </Paragraph2>
      </Col>
    </Row>
  );
};

export default HomeInviteCard;
