import { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Separator } from "../../_components";
import NavLink from "./NavLink";

import { doLogoutAsync } from "../../_redux/actions/auth/doLogoutAsync";
import { useGetMenuItems } from "./_utils";

const UserMenuMobile = ({ show, setShow, height, doLogout }) => {
  const navigate = useNavigate();
  const [showClasses, setShowClasses] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showProgram, setShowProgram] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const classItems = useGetMenuItems("classes");
  const mainItems = useGetMenuItems("main");
  const profileItems = useGetMenuItems("profile");
  const programItems = useGetMenuItems("program");
  const progressItems = useGetMenuItems("progress-steps");

  const subMenuNotOpened = !showClasses && !showProfile && !showProgram && !showProgress;

  const subMenus = ["classes", "profile", "programs", "progress"];

  useEffect(() => {
    if (!show) {
      setShowClasses(false);
    }
  }, [show]);

  const menuItemClick = (item) => {
    if (!["classes", "user-outline", "program", "progress-steps"].includes(item.icon))
      return handleLeavingNav();
    if (item.icon === "classes") return setShowClasses(true);
    if (item.icon === "program") return setShowProgram(true);
    if (item.icon === "progress-steps") return setShowProgress(true);
    if (item.icon === "user-outline") return setShowProfile(true);
  };

  const backButtonClick = () => {
    setShowClasses(false);
    setShowProfile(false);
    setShowProgram(false);
    setShowProgress(false);
  };

  const handleLeavingNav = () => {
    setShow(false);
    setShowClasses(false);
    setShowProfile(false);
    setShowProgram(false);
    setShowProgress(false);
  };

  const logout = () => doLogout(navigate);

  return (
    <MenuWrapper $show={show} $height={height}>
      <NavWrapper>
        {subMenuNotOpened &&
          mainItems?.map((item, index) => {
            if (item.hide) return null;
            let extraPadding = subMenus.includes(item.label?.toLowerCase()) ? true : false;
            return (
              <NavSpacing key={`profile-menu-mobile-${index}`} $extraPadding={extraPadding}>
                <NavLink
                  mobile
                  key={item.icon}
                  name={item.icon}
                  label={item.label}
                  linkTo={item.link}
                  showMore={item.showMore}
                  type={item.link ? "link" : null}
                  onClick={() => menuItemClick(item)}
                  parentFrom={"user_mobile_dropdown"}
                />
              </NavSpacing>
            );
          })}
        {(showClasses || showProfile || showProgram || showProgress) && (
          <BackButtonWrapper>
            <NavLink
              mobile
              key={"back"}
              name="chevron-left-circle"
              label="Back"
              type={null}
              onClick={backButtonClick}
            />
          </BackButtonWrapper>
        )}
        {showClasses &&
          classItems?.map((classItem, index) => {
            return (
              <NavLink
                key={classItem.link || index}
                mobile
                label={classItem.label}
                linkTo={classItem.link}
                type="link"
                onClick={handleLeavingNav}
                parentFrom={"user_mobile_dropdown"}
              />
            );
          })}
        {showProgram &&
          programItems?.map((programItem, index) => {
            return (
              <NavLink
                key={programItem.link || index}
                mobile
                label={programItem.label}
                linkTo={programItem.link}
                type="link"
                onClick={handleLeavingNav}
                parentFrom={"user_mobile_dropdown"}
              />
            );
          })}
        {showProgress &&
          progressItems?.map((progressItem, index) => {
            return (
              <NavLink
                key={progressItem.link || index}
                mobile
                label={progressItem.label}
                linkTo={progressItem.link}
                type="link"
                onClick={handleLeavingNav}
                parentFrom={"user_mobile_dropdown"}
              />
            );
          })}
        {showProfile && (
          <>
            {profileItems?.map((item, index) => {
              return item?.hide ? null : item?.line ? (
                <Separator centered $width={"100%"} $my={1} key={`profile-mobile-menu-${index}`} />
              ) : (
                <NavLink
                  key={item.link || index}
                  mobile
                  label={item.label}
                  linkTo={item.link}
                  type="link"
                  onClick={handleLeavingNav}
                  parentFrom={"user_mobile_dropdown"}
                />
              );
            })}
            <NavLink key={"logout"} mobile label="Logout" onClick={logout} />
          </>
        )}
      </NavWrapper>
    </MenuWrapper>
  );
};

const NavSpacing = styled.div`
  padding-top: ${({ $extraPadding }) => ($extraPadding ? "0.5rem" : "0")};
  padding-bottom: ${({ $extraPadding }) => ($extraPadding ? "0.5rem" : "0")};
`;

const MenuWrapper = styled.div`
  position: fixed;
  top: ${({ $height }) => $height}px;
  right: ${({ $show }) => ($show ? "0" : "-105vw")};
  bottom: 0;
  width: 100vw;
  background-color: ${(props) => props.theme.palette.neutralWhite};
  z-index: 1045;
  overflow: auto;
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  padding: 10px 30px;
  transition: all 0.2s, width 0.2s;
  transition-timing-function: ease;
`;

const NavWrapper = styled(Nav)`
  background-color: ${(props) => props.theme.palette.neutralWhite};
  padding: 20px;
  display: flex;
  list-style-type: none;
  flex-direction: column;
  overflow-y: auto;
`;

const BackButtonWrapper = styled.div`
  margin-left: -10px !important;
`;

export default connect(null, { doLogout: doLogoutAsync })(UserMenuMobile);
