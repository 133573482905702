import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import secureLocalStorage from "react-secure-storage";

import store from "../_redux/store";

import { apiUrl, headers } from "../constants";
import { TOKEN_SUCCESS, TOKEN_FAILURE } from "../_redux/reducers/auth/token";

/*
  What: This file exports a custom axios function, which can be used like any regular axios function.
  Why: This function includes the interceptor middleware to automatically add needed headers on requests
       and to intercept requests that fail due to expired tokens and automatically refresh the auth token
  Use: Import and use like a standard axios API call -- nothing additional is needed to use
*/

const refreshSuccess = (token) => ({ type: TOKEN_SUCCESS, token });
const refreshFailure = (errors) => ({ type: TOKEN_FAILURE, errors });

const dispatch = store.dispatch;

export const bearerToken = () => {
  const savedToken = secureLocalStorage.getItem("token");
  const token = savedToken ? JSON.parse(savedToken) : null;

  return token?.access ? `Bearer ${token.access}` : null;
};

const refreshToken = () => {
  const savedToken = secureLocalStorage.getItem("token");
  const token = savedToken ? JSON.parse(savedToken) : null;
  return token?.refresh || null;
};

const authAxios = axios.create({
  baseURL: `${apiUrl}/`,
  headers: { ...headers },
});

authAxios.interceptors.request.use((request) => {
  request.headers.Authorization = bearerToken();
  // request.withCredentials = true;
  return request;
});

const refreshAuthLogic = (failedRequest) =>
  authAxios
    .post(`${apiUrl}/token/refresh/`, {
      refresh: refreshToken(),
    })
    .then((tokenRefreshResponse) => {
      const { access } = tokenRefreshResponse.data;
      dispatch(refreshSuccess({ access: access, refresh: refreshToken() }));
      failedRequest.response.config.headers["Authorization"] = `Bearer ${access}`;
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(
        refreshFailure({
          status: error.status,
          messages: error.data,
        })
      );
      localStorage.setItem("redirect", window.location.pathname);
      return Promise.reject(error);
    });

const options = { pauseInstanceWhileRefreshing: true };

createAuthRefreshInterceptor(authAxios, refreshAuthLogic, options);

export default authAxios;
