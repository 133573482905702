// @flow
export const iPhoneSEHeight = 667;
export const formFieldHeight = 50;
export const formFieldContainerHeight = 60;
export const buttonHeight = 50;
export const mediumButtonHeight = 40;
export const smallButtonHeight = 30;
export const extraSmallButtonHeight = 20;
export const googleButtonHeight = 50;
export const cardHeaderHeight = 40;
export const fastAnimationDuration = 150;
export const extraLargeFontSize = 30;
export const largeFontSize = 25;
export const mediumFontSize = 20;
export const mediumFontHeight = 26;
export const mediumFontHalfHeight = mediumFontHeight / 2;
export const smallerFontSize = 12;
export const smallFontSize = 16;
export const smallFontHeight = 21;
export const extra18LargeLength = 200;
export const extra17LargeLength = 180;
export const extra16LargeLength = 160;
export const extra15LargeLength = 140;
export const extra14LargeLength = 120;
export const extra13LargeLength = 100;
export const extra12LargeLength = 90;
export const extra11LargeLength = 80;
export const extra10LargeLength = 70;
export const extra9LargeLength = 60;
export const extra8LargeLength = 55;
export const extra7LargeLength = 50;
export const extra6LargeLength = 45;
export const extra5LargeLength = 45;
export const extra4LargeLength = 40;
export const extraExtraExtraLargeLength = 35;
export const extraExtraLargeLength = 30;
export const extraLargeLength = 25;
export const largeLength = 20;
export const mediumLength = 15;
export const smallLength = 10;
export const mediumProgressBarHeight = 10;
export const buttonBorderRadius = 25;
export const smallBorderRadius = 8;
export const mediumBorderRadius = 10;
export const largeBorderRadius = 15;
export const extraLargeBorderRadius = 20;
export const extraExtraLargeBorderRadius = 25;
export const extraExtraExtraLargeBorderRadius = 30;
export const extraSmallLength = 5;
export const extraExtraSmallLength = 2;
export const thinBorderWidth = 1.5;
export const thickBorderWidth = 3;
export const smallBarHeight = 40;
export const smallIconSize = 20;
export const mediumIconSize = 25;
export const largeIconSize = 30;
export const extraLargeIconSize = 35;
export const extraExtraLargeIconSize = 40;
export const extraExtraExtraLargeIconSize = 45;
export const extra4LargeIconSize = 50;
export const extra5LargeIconSize = 55;
export const extra6LargeIconSize = 60;
export const extra7LargeIconSize = 65;
export const extra8LargeIconSize = 70;
export const extra9LargeIconSize = 75;
export const extra10LargeIconSize = 80;
export const extraExtraExtraSmallAvatarSize = 50;
export const inviteListSmallAvatarSize = 30;
export const invitedButtonSize = 100;
export const smallAvatarSize = 48;
export const mediumAvatarSize = 150;
export const millisecond = 1;
export const mediumStarSize = 25;
export const smallStarSize = 15;
export const largeStarSize = 50;
export const smallNotificationDotSize = 10;
export const maxNumberOfStars = 5;
export const secondInMilliseconds = millisecond * 1000;
export const minuteInMilliseconds = secondInMilliseconds * 60;
export const hourInMilliseconds = minuteInMilliseconds * 60;
export const dayInMilliseconds = hourInMilliseconds * 24;
export const weekInMilliseconds = dayInMilliseconds * 7;
export const seekBarHeight = 40;
export const friendsVideoSize = 150;
export const numberOfSecondsToHideVideoControls = 5;
export const seekBarTimeAndClosedCaptionHeight = 140;
export const myCameraHeight = 75;
export const myCameraWidth = 130;
export const myVideoSize = 300;
export const closeIconContainerSize = largeIconSize + extraSmallLength * 2;
export const inviteButtonSize = 100;
// export const widthWithSmallPadding = dimensions.width - smallLength * 3;
export const titleImageHeight = 50;
export const multiLineInputHeight = 100;
export const loadingIndicatorContainerSize = 75;
export const tabViewHeight = mediumButtonHeight + smallLength * 2;
export const multilineTextInputHeight = 100;
export const tabBarHeight = 56;

// New Metrics
export const profileListItemHeight = 68;
export const profileListItemBorderRadius = 16;

// Buttons
export const buttonMetrics = {
  borderWidth: 2,
  paddingLeft: 24,
  paddingRight: 24,
  imagePadding: 12,
  fontSize: 18,
  lineHeight: 24,
  heightLarge: 72,
  heightNormal: 50,
  borderRadiusLarge: 36,
  borderRadiusNormal: 25,
  borderStyle: "solid",
};

export const cardMetrics = {
  borderRadius: 24,
  padding: 24,
  spacing: 8,
  progressSize: 40,
  boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.12)",
  liveCircleSize: "16px",
  liveCircleRadius: "128px",
  barChartHeight: 16,
  barChartRadius: 128,
};
