import { combineReducers } from "redux";

import token from "./token";
import login from "./login";
import refresh from "./refresh";
import register from "./register";

export default combineReducers({
  token,
  login,
  refresh,
  register,
});
