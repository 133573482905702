import { useNavigate, useRouteError } from "react-router-dom";

import ROUTES from "./constants";

import NonAuthLayout from "../App/NonAuthLayout";
import Button from "../_components/Buttons/Button";
import { Col, Heading3, Row } from "../_components";
import { useEffect } from "react";

const ErrorBoundary = () => {
  const error = useRouteError();
  const envr = import.meta.env.VITE_APP_ENV || "development";

  useEffect(() => {
    // Error in console
    // Not all errors are 404s, 404 default to show for end user
    if (envr !== "production" || envr !== "prod") {
      console.log(error);
    }
  }, [error, envr]);

  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate(ROUTES.DASHBOARD);
  };

  return (
    <NonAuthLayout>
      <Row>
        <Col $align="center">
          <Heading3>404 - Page not found</Heading3>
          <Button $my={2} onClick={handleRedirect}>
            Return to Dashboard
          </Button>
        </Col>
      </Row>
    </NonAuthLayout>
  );
};

export default ErrorBoundary;
