import styled from "styled-components";
import GeGUI from "../_styles/ge-styled-components";

const PointsPill = ({ pointsAmount = "", ...props }) => {
  return (
    <GUI.PointsPill {...props}>
      {pointsAmount}
      <img className="ml-2" src="/images/lightning.svg" alt="lightning" />
    </GUI.PointsPill>
  );
};

const GUI = {
  PointsPill: styled(GeGUI.Button.Pill)`
    margin: 0px !important;
    height: auto !important;
    min-height: unset !important;
    padding: 3px 16px !important;
    font-size: 13px;
    line-height: 18px;
    font-weight: bold;
    cursor: pointer;
    white-space: nowrap;
    width: 77px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  `,
};

export default PointsPill;
