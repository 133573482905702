import { makeUseAxios } from "axios-hooks";
import authAxios from "../_utils/authAxios";

// Please see this repository for more information: https://github.com/simoneb/axios-hooks
// Core reason behind this: this is built using our custom axios function, so it will automatically refresh the authorization token

/*
Basic use:

import useAuthAxios from "../_hooks/useAuthAxios";

const ExampleComponent = () => {
  const [{ data, loading, error }, refetch] = useAuthAxios(
    'example/route/here'
  )

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error!</p>

  return (
    <div>
      <button onClick={refetch}>refetch</button>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  )
}
*/

/*
    Other Notes:
        -The default function runs on mount and only re-runs if the parameters change (like the API route)
        -There are options to make the function not run on mount (see the repository regarding manual requests)
        -The refetch function can be aliased as anything and is used only to re-run the call (it will run automatically on mount)
*/

const useAuthAxios = makeUseAxios({ axios: authAxios });

export default useAuthAxios;
