import { memo, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Row, Paragraph1 } from "../../../_components";

import { UserContext } from "../../../_context/UserContext";

import { ROUTES } from "../../../_routes";

const RescheduleButton = ({ course, event, canJoin }) => {
  const navigate = useNavigate();
  const { userData, loading } = useContext(UserContext);
  if (!["group", "solo", "open_door"].includes(event?.type)) return null;

  const toggleShow = (e) => {
    e && e.stopPropagation();
    navigate(`${ROUTES.COURSE}/${course.id}${ROUTES.SCHEDULE}/${event?.id}`);
  };

  return (
    <>
      {!loading &&
        ((event?.host === userData?.id &&
          (event?.type === "group" || event?.type === "open_door")) ||
          event?.type === "solo") &&
        !canJoin && (
          <Row $mt={2}>
            <Paragraph1 cursor="pointer" onClick={toggleShow} weight={700}>
              Reschedule
            </Paragraph1>
          </Row>
        )}
    </>
  );
};

export default memo(RescheduleButton);
