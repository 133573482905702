import { memo, useContext, useState, useEffect, useRef } from "react";
import styled from "styled-components";

import { authAxios } from "../../_utils";
import { Heading5, Heading6, Row, Button, PageLoader } from "../../_components";
import { UserContext } from "../../_context/UserContext";
import { ModalMain } from "../../_components/Modal";
import { useDimensions } from "../../_hooks";

export const ContractAgreementMain = () => {
  const divRef = useRef(null);
  const { height } = useDimensions(divRef);
  const { userData, fetch: fetchUser } = useContext(UserContext);
  const { isInstructor, isOwner } = userData;
  const [contract, setContract] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    authAxios
      .get("profiles/partner/contract")
      .then(({ data }) => setContract(data?.data))
      .finally(() => setLoading(false));
  }, []);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = contract?.url;
    link.target = "_blank";
    link.download = "BurnalongPartnerAgreement";
    link.click();
  };

  // this code is should download directly, but currently we have a cors issue
  // const handleDownload = () => {
  //   fetch(contract?.url)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       let objectURL = URL.createObjectURL(blob);
  //       const link = document.createElement("a");
  //       link.href = URL.createObjectURL(blob);
  //       link.download = "BurnalongPartnerAgreement";
  //       document.body.appendChild(link);
  //       link.dispatchEvent(
  //         new MouseEvent("click", {
  //           bubbles: true,
  //           cancelable: true,
  //           view: window,
  //         })
  //       );
  //       link.remove();
  //       window.URL.revokeObjectURL(objectURL);
  //     })
  //     .catch(console.error);
  // };

  const handleAccept = () => {
    const data = {};
    isOwner && (data.partner_agreement = contract?.version);
    isInstructor && !isOwner && (data.partner_approval = true);
    authAxios.post(`profiles/partner/contract`, data).then(() => {
      fetchUser();
    });
  };

  return (
    <BackgroundWrapper>
      <Main ref={divRef} $height={isInstructor && !isOwner ? "auto" : "80%"}>
        <ModalMain>
          {loading ? (
            <PageLoader />
          ) : isInstructor && !isOwner ? (
            <InstructorShow handleAccept={handleAccept} />
          ) : (
            <OwnerShow
              height={height}
              src={contract?.url}
              handleAccept={handleAccept}
              handleDownload={handleDownload}
            />
          )}
        </ModalMain>
      </Main>
    </BackgroundWrapper>
  );
};

const BackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9998;
  background: rgba(0, 0, 0, 0.6);
`;

const Main = styled.div`
  position: fixed;
  background: white;
  width: 80%;
  height: ${({ $height }) => $height};
  top: 50%;
  left: 50%;
  z-index: 9999;
  border-radius: 24px;
  transform: translate(-50%, -50%);
`;

const OwnerShow = ({ src, handleDownload, handleAccept, height }) => {
  const titleDivRef = useRef(null);
  const rowDivRef = useRef(null);
  const { height: titleDivHeight } = useDimensions(titleDivRef);
  const { height: rowDivHeight } = useDimensions(rowDivRef);
  return (
    <>
      <div ref={titleDivRef}>
        <Heading5 $textAlign="center" weight={700}>
          We've updated the Partner Agreement!
        </Heading5>
        <Heading6 $textAlign="center" weight={700} $pt={8} $pb={4}>
          Burnalong Content Provider Agreement
        </Heading6>
      </div>

      <embed
        src={`${src}#toolbar=0`}
        type="application/pdf"
        width="100%"
        height={height - titleDivHeight - rowDivHeight * 2}
      />
      <div ref={rowDivRef}>
        <Row $justify="space-between" $pt={4} $mt="auto">
          <Button
            id={`contract-agreement-download`}
            onClick={handleDownload}
            bType="text"
            padding="0px"
          >
            Download
          </Button>
          <Button id={`contract-agreement-accept`} onClick={handleAccept}>
            Accept
          </Button>
        </Row>
      </div>
    </>
  );
};

const InstructorShow = ({ handleAccept }) => {
  return (
    <>
      <Heading5 $textAlign="center" weight={700}>
        We've updated the Partner Agreement!
      </Heading5>
      <Heading6 $textAlign="center" weight={700} $pt={8}>
        Your employer is required to review and accept the new terms and conditions.
      </Heading6>
      <Row $justify="flex-end" $pt={4}>
        <Button onClick={handleAccept}>Accept</Button>
      </Row>
    </>
  );
};

const ContractAgreement = ({ show }) => (show ? <ContractAgreementMain /> : null);

export default memo(ContractAgreement);
