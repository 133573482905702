import React, { useEffect } from "react";

export const GoogleTranslate = () => {
  function moveTranslateWidgetToPlaceholder() {
    const placeholder = document.getElementById("translate-placeholder");
    const translateWidget = document.getElementById("google_translate_element");

    if (placeholder && translateWidget) {
      placeholder.appendChild(translateWidget);
      translateWidget.style.display = "block"; // display the widget when it's in the placeholder
    }
  }

  // move the widget back out of the react dom because otherwise react will
  // delete the widget because the google translate script generates the widget
  // outside of the react dom so react doesnt know about it and will remove
  // it after a remount
  function moveTranslateWidgetToHoldingArea() {
    const holdingArea = document.getElementById("translate-holding-area");
    const translateWidget = document.getElementById("google_translate_element");

    if (holdingArea && translateWidget) {
      holdingArea.appendChild(translateWidget);
      translateWidget.style.display = "none"; // hide the widget when it's in the holding area
    }
  }

  useEffect(() => {
    moveTranslateWidgetToPlaceholder();

    return () => {
      moveTranslateWidgetToHoldingArea();
    };
  }, []);

  return (
    <div
      id="translate-placeholder"
      style={{
        position: "relative",
        width: 150,
        height: 40,
        overflow: "visible",
      }}
    />
  );
};
