import Select, { components } from "react-select";
import styled from "styled-components";
import Icon from "../Icon";

const SquareDropdown = styled(Select)`
  & .Form-Select__control {
    width: ${(props) => props.controlWidth || 100}px;
    height: ${(props) => props.controlHeight || 50}px;
    margin: ${(props) => props.margin};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    border: ${(props) => props.border || "0.25px solid" + props.theme.palette.primaryBlue};
    border-radius: 8px;
    cursor: pointer;
    border: ${(props) => props.border || "0.25px solid" + props.theme.palette.primaryBlue};
    background-color: ${(props) => props.controlbackgroundColor || props.theme.palette.neutralGrey};
    &:hover {
      color: ${(props) => props.theme.palette.primaryBlue};
      border-color: transparent;
      border: ${(props) => props.border || "0.25px solid" + props.theme.palette.primaryBlue};
    }
  }
  & .Form-Select__control--is-focused {
    cursor: pointer;
    box-shadow: 0 0 0 0;
  }
  & .Form-Select__menu--is-open {
    border-color: transparent;
    cursor: pointer;
  }
  & .Form-Select__control--menu--is-open {
    border-color: transparent;
    cursor: pointer;
  }
  & .Form-Select__menu {
    width: ${(props) => (props.menuWidth ? props.menuWidth : props.controlWidth)}px;
    flex-grow: 0;
    border-radius: 8px;
    cursor: pointer;
    background-color: ${(props) => props.menuBackgroundColor || props.theme.palette.neutralGrey};
    transition: box-shadow 300ms ease;
  }
  & .Form-Select__indicator-separator {
    display: none;
  }
  & .Form-Select__option {
    color: ${(props) => props.theme.palette.primaryBlue};
  }
  & .Form-Select__option--is-focused {
    background-color: ${(props) => props.optionHoverBackgroundColor || "transparent"};
    color: ${(props) => props.optionHoverFontColor || props.theme.palette.neutralWhite};
    &:active {
      background-color: ${(props) => props.optionHoverBackgroundColor || "transparent"};
      color: ${(props) => props.optionHoverFontColor || props.theme.palette.neutralWhite};
    }
    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  & .Form-Select__option--is-selected {
    color: ${(props) => props.theme.palette.primaryBlue};
    background-color: transparent;
    &:hover {
      background-color: ${(props) => props.optionHoverBackgroundColor || "transparent"};
      color: ${(props) => props.optionHoverFontColor || props.theme.palette.optionsFontColor};
    }
  }
  & .Select__value-container {
    display: flex;
    padding-right: ${(props) => props.valueContainerPaddingRight || "16px"};
    padding-left: ${(props) => props.valueContainerPaddingLeft};
    text-overflow: ${(props) => props.valueContainerTextOverflow || "ellipsis"};
  }
  & .Form-Select__dropdown-indicator {
    color: ${(props) => props.theme.palette.neutralDarkerGrey};
    margin-right: 9px;
    &:hover {
      color: ${(props) => props.theme.palette.neutralDarkerGrey};
    }
  }
  & .Form-Select__single-value {
    font-size: 18px;
    font-weight: normal;
    margin-left: 4px;
    color: ${(props) => props.theme.palette.primaryBlue};
  }
  & .Form-Select__menu-list {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const SelectIcon = styled(Icon)`
  margin-right: 10px;
  font-size: ${(props) => props.iconSize || "16px"};
`;

const SingleValueIcon = styled(Icon)`
  margin-right: 10px;
  font-size: ${(props) => props.iconSize || "16px"};
`;

const { Option, SingleValue } = components;
const IconOption = (props) => (
  <Option {...props}>
    {props.data.icon ? (
      <SelectIcon color={props.iconColor || props.data.iconColor} name={props.data.icon || ""} />
    ) : null}
    {props.data.label}
  </Option>
);

const IconSingleValue = (props) => (
  <SingleValue {...props}>
    {props.data.icon ? <SingleValueIcon name={props.data.icon || ""} /> : null}
    {props.data.label}
  </SingleValue>
);

const FormDropdown = (props) => {
  return (
    <SquareDropdown
      styles={{
        dropdownIndicator: (provided, state) => ({
          ...provided,
          transform: state.selectProps.menuIsOpen && "rotate(180deg)",
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          overflow: props.valueContainerOverflow,
        }),
      }}
      components={{ Option: IconOption, SingleValue: IconSingleValue }}
      classNamePrefix={props.classNamePrefix || "Form-Select"}
      {...props}
    />
  );
};

export default FormDropdown;
