const typoMap = {
  display: {
    1: {
      fontSize: 75,
      lineHeight: 95,
    },
    2: {
      fontSize: 62,
      lineHeight: 78,
    },
  },
  heading: {
    1: {
      fontSize: 48,
      lineHeight: 60,
    },
    2: {
      fontSize: 42,
      lineHeight: 54,
    },
    3: {
      fontSize: 38,
      lineHeight: 48,
    },
    4: {
      fontSize: 32,
      lineHeight: 40,
    },
    5: {
      fontSize: 26,
      lineHeight: 33,
    },
    6: {
      fontSize: 20,
      lineHeight: 25,
    },
  },
  paragraph: {
    1: {
      fontSize: 18,
      lineHeight: 24,
    },
    2: {
      fontSize: 16,
      lineHeight: 22,
    },
    3: {
      fontSize: 14,
      lineHeight: 18,
    },
    4: {
      fontSize: 12,
      lineHeight: 15,
    },
  },
};

export default typoMap;
