import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT } from "./login";
import secureLocalStorage from "react-secure-storage";

export const TOKEN_REQUEST = "auth/TOKEN_REQUEST";
export const TOKEN_SUCCESS = "auth/TOKEN_SUCCESS";
export const TOKEN_FAILURE = "auth/TOKEN_FAILURE";

const savedToken = secureLocalStorage.getItem("token");
const tokenTime = secureLocalStorage.getItem("tokenTime");
const initialState = savedToken ? { ...JSON.parse(savedToken), tokenTime: tokenTime } : null;

export const login = (state = initialState, action) => {
  const { token } = action;

  switch (action.type) {
    case LOGIN_SUCCESS:
    case TOKEN_SUCCESS:
      secureLocalStorage.setItem("token", JSON.stringify(token));
      const successTime = new Date();
      secureLocalStorage.setItem("tokenTime", successTime);
      return {
        ...token,
        tokenTime: successTime,
      };
    case LOGIN_FAILURE:
    case TOKEN_FAILURE:
    case LOGOUT:
      secureLocalStorage.removeItem("token");
      return null;
    default:
      return state;
  }
};

export default login;
