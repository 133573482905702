import React from "react";
import styled from "styled-components";
import { Button, FormControl, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";

import authAxios from "../_utils/authAxios";

import { ROUTES } from "../_routes";

import { Paragraph1, Row } from ".";
import OptionMenu from "./OptionMenu";

const generateLink = (course) => {
  const host = window.location.host;
  const event = course?.event || course?.schedule || course;
  const eventType = event?.type;

  if (eventType === "live")
    return `${host}${ROUTES.COURSE}/${course.id}${ROUTES.LIVE}/${event?.id}`;
  else if (eventType === "open_door")
    return `${host}${ROUTES.COURSE}/${course.id}${ROUTES.OPEN_DOOR}/${event?.id}`;
  else return `${host}${ROUTES.COURSE}/${course?.id}`;
};

const ShareCourseLink = ({ course }) => {
  const link = generateLink(course);

  const handleShareClick = () => {
    const event = course?.event || course?.schedule || course;
    const eventType = event?.type;
    const data = {
      type: "course-detail",
      app_id: course?.id,
      app_type: eventType,
    };
    authAxios.post(`/shared/`, data);
    navigator.clipboard.writeText(link);
  };

  const ShareCourseLink = () => {
    return (
      <OptionMenu dropdown="share-link" showButton label="share-link">
        <div id={`share-course-${course?.id}`}>
          <Row $align="center" cursor="pointer" $my={2} onClick={handleShareClick}>
            <OverlayTrigger
              overlay={<Tooltip id={`tooltip-share-${course?.id || null}`}>Click to copy</Tooltip>}
            >
              <div>
                <InputGroup>
                  <Paragraph1 weight={700}>Share</Paragraph1>
                  <CopyText placeholder={link} title={link} disabled />
                  <CopyButton
                    id={`copy-${course?.id || null}`}
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleShareClick();
                      }
                    }}
                  >
                    Copy
                  </CopyButton>
                </InputGroup>
              </div>
            </OverlayTrigger>
          </Row>
        </div>
      </OptionMenu>
    );
  };

  const handlePropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <div id={`share-menu-${course.id}`}>
        <CircleButton onClick={handlePropagation}>
          <ShareCourseLink />
        </CircleButton>
      </div>
    </>
  );
};

const CopyText = styled(FormControl)`
  border-radius: 25px 0 0 25px;
`;

const CopyButton = styled(Button)`
  border-radius: 0 25px 25px 0;
`;

const CircleButton = styled.div`
  margin: 0 0.5rem;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  padding: 7px;
  cursor: pointer;
`;

export default ShareCourseLink;
