import { Icon } from "../../_components/Icon";
import styled from "styled-components";
import { Row } from "../../_components/Layout";
import { Heading6, Heading5 } from "../../_components/Typography";

export const StarRating = ({
  avgRating,
  rating,
  largeCount,
  iconSize = "1.5em",
  hideNum = false,
  justify = "center",
  ...props
}) => {
  const ratingAvg = avgRating ? avgRating : rating ? rating : 0;
  const absRating = Math.floor(ratingAvg);
  const decimalRating = ratingAvg - absRating;

  return (
    <Row
      $align={"center"}
      $justify={justify}
      title={`${absRating} star${absRating > 1 ? "s" : ""}`}
      {...props}
    >
      {[...Array(absRating).keys()].map(function (_, i) {
        return (
          <StarIconWrapper key={i}>
            <Icon name="star" fill="brightOrange" height={iconSize} width={iconSize} />
          </StarIconWrapper>
        );
      })}
      {decimalRating > 0 ? (
        <StarIconWrapper>
          <Icon name="star-half" fill="brightOrange" height={iconSize} width={iconSize} />
        </StarIconWrapper>
      ) : (
        [...Array(5 - absRating).keys()].map(function (_, i) {
          return (
            <StarIconWrapper key={i}>
              <Icon name="star" fill="grey1" height={iconSize} width={iconSize} />
            </StarIconWrapper>
          );
        })
      )}

      {!hideNum && absRating && !largeCount ? (
        <Heading6 weight={700}>{ratingAvg}</Heading6>
      ) : !hideNum && absRating && largeCount ? (
        <Heading5 weight={700}>{ratingAvg}</Heading5>
      ) : null}
    </Row>
  );
};

export default StarRating;

const StarIconWrapper = styled.span`
  display: "inline-block";
  padding-right: 0.5rem;
`;
