import React from "react";
import styled from "styled-components";
import DEFAULT_IMAGE from "../_media/images/Burnalong_Icon_MasterBlue.png";

const BrandPlaceHolder = ({
  width = "100%",
  height = "100%",
  aspectRatio,
  logoSize,
  mx,
  p,
  hero,
}) => {
  return (
    <DefaultPlaceHolder $width={width} $height={height} $aspectRatio={aspectRatio} $p={p} $mx={mx}>
      <ImageLogo
        src={DEFAULT_IMAGE}
        alt="Brand Placeholder"
        $width={width}
        $logoSize={logoSize}
        $hero={hero}
        $multiplier={hero ? 0.2 : 0.3}
      />
    </DefaultPlaceHolder>
  );
};

const DefaultPlaceHolder = styled.div`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio && `${$aspectRatio} !important`};
  background-color: ${({ theme }) => theme.palette.baseTeal};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${({ $p }) => $p};
  margin-left: ${({ $mx }) => $mx};
  margin-right: ${({ $mx }) => $mx};
`;

const ImageLogo = styled.img`
  width: ${({ $width, $logoSize, $multiplier }) =>
    $logoSize ? $logoSize : `calc(${$width} * ${$multiplier})`};
  height: auto;

  @media screen and (max-width: 576px) {
    width: ${({ $hero }) => $hero && "75px"};
  }
  @media screen and (min-width: 576px) {
    width: ${({ $hero }) => $hero && "165px"};
  }
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

export default BrandPlaceHolder;
