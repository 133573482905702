import { createGlobalStyle } from "styled-components";
import { primaryBlue } from "./colors";

const GlobalStyle = createGlobalStyle`

  body {
    margin: 0px;
    padding: 0;
    font-family: 'DM Sans', sans-serif;
  }

  a {
    cursor: pointer;
  }

  p, .p {
    font-size: 18px;
    line-height: 24px;
  }

  b {
    font-weight: 700;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'DM Sans', sans-serif;
    color: ${primaryBlue};
  }


`;

export default GlobalStyle;
