export const LOGIN_REQUEST = "auth/LOGIN_REQUEST";
export const LOGIN_SUCCESS = "auth/LOGIN_SUCCESS";
export const LOGIN_FAILURE = "auth/LOGIN_FAILURE";
export const LOGOUT = "auth/LOGOUT";

const initialState = {
  isFetching: false,
  errors: null,
  status: null,
};

export const login = (state = initialState, action) => {
  const { errors, status } = action;

  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errors: null,
      };
    case LOGIN_FAILURE:
      return {
        isFetching: false,
        errors,
        status,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default login;
